import react, { useState, useEffect } from 'react';
import { Box, Hidden, makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import classnames from 'classnames';
import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import MovieCardHome from '../MovieCardSimple/MovieCardHome';
import styles from './styles';
import Backend_API from '../../../../api';
import { useHistory } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // import the styles

const useStyles = makeStyles(styles);
// import 'swiper/swiper-bundle.css';
// const Backend_API = `http://localhost:8080`;

function NextArrow(props) {
  const { currentSlide, slideCount, onClick } = props;
  const classes = useStyles({ currentSlide, slideCount });
  return (
    <div className={classnames(classes.arrow, 'nextArrow')} onClick={onClick}>
      <Box className={classes.arrowIcon}>
        <ArrowForwardIcon color="inherit" fontSize="large" />
      </Box>
    </div>
  );
}

function PrevArrow(props) {
  const { currentSlide, onClick } = props;
  const classes = useStyles({ currentSlide });
  return (
    <div className={classnames(classes.arrow, 'prevArrow')} onClick={onClick}>
      <Box className={classes.arrowIcon}>
        <ArrowBackIcon color="inherit" fontSize="large" />
      </Box>
    </div>
  );
}

function SingleMovieCarousel({
  carouselClass,
  movies = [],
  showtimes = [],
  cinemas = [],
  title,
  to = null,
  props,
}) {
  const history = useHistory();
  const [banner, setBanner] = useState([]);
  const classes = useStyles();
  const settings = {
    centerMode: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    swipeToSlide: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const BannerImages = async () => {
    let BASEURL = `${Backend_API}`;
    let url;
    url =
      history.location.pathname === '/private/movies'
        ? `/users/banner/ads?public=false`
        : `/users/banner/ads?public=true`;
    const response = await fetch(BASEURL + url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();
    // console.log('this is banner daata', data);
    setBanner(data);
  };
  useEffect(() => {
    BannerImages();
  }, []);

  const handleImageClick = siteLink => {
    console.log('handleImageClick called with siteLink:', siteLink);
    history.replace(siteLink)
    // return
    // window.location.href = siteLink;
  };

  return (
    <div className={carouselClass}>
      <Slider {...settings} className={classes.slider}>
        {banner.map(item =>
          item.map(images => (
            <MovieCardHome
              image={images.image}
              siteLink={images.siteLink}
              handleImageClick={handleImageClick}
            />
          ))
        )}
      </Slider>
      {/* <Hidden only={['lg']}>
        
        <Carousel
          showArrows={true}
          // onChange={onChange}
          // onClickItem={onClickItem}
          // onClickThumb={onClickThumb}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          infiniteLoop
          autoPlay={true ? true : false}
          interval={5000}
          centerMode={true ? true : false}
          centerSlidePercentage={true ? 80 : 50}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            overflow: 'hidden',
            maxWidth: '100%',
            height: '50vh', // set a fixed height for the carousel
          }}
        >
          {banner.map(item =>
            item.map(images => (
              <div key={images.siteLink}>
                <a href={images.siteLink} rel="noopener noreferrer">
                  <img
                    src={images.image}
                    alt={images.title}
                    style={{
                      objectFit: 'cover',
                      // maxWidth: '100%',
                      maxHeight: '50vh', // set a max height that maintains aspect ratio
                      height: '30vh',
                      width: `${(parseFloat(carouselHeight) / images.aspectRatio) * 1.5}px`,
                    }}
                  />
                </a>
              </div>
            ))
          )}
        </Carousel>
      </Hidden> */}
    </div>
  );
}
export default SingleMovieCarousel;
