import { Box, Container, Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import soundSystemIcon from '../../../../assets/icons/cinema.png';
import vipIcon from '../../../../assets/icons/g87.png';
import iCafeIcon from '../../../../assets/icons/Icon ionic-ios-cafe.png';
import threeDIcon from '../../../../assets/icons/Icon metro-3d-rotation.png';
import privateIcon from '../../../../assets/icons/Layer 7.png';
import priminmIcon from '../../../../assets/icons/premium.png';

const About = ({ classes }) => {
  const Item = ({ img, title, content }) => (
    <Paper elevation={1} style={{ borderRadius: '10px', height: '100%' }}>
      <Box padding="20px" className={classes.experienceCardContainer}>
        <Typography className={classes.subtitle} variant="h3" gutterBottom>
          {title}
        </Typography>

        <Box className={classes.iconContainer2}>
          <img src={img} alt="icon" width="100%" />
        </Box>
      </Box>

      <Box padding="20px" style={{ marginTop: '-25px' }}>
        <Typography gutterBottom>{content}</Typography>
      </Box>
    </Paper>
  );

  return (
    <Box className={classes.bggray}>
      <Container maxWidth="lg">
        <Box>
          <Typography className={classes.heading} variant="h2" gutterBottom>
            About <span className={classes.redText}>Innovative Multiplex</span>
          </Typography>
          <Typography gutterBottom>
            In 2003, Innovative Multiplex made its mark as the first multiplex
            in Karnataka with a seating capacity of 1800 and 9 world class
            screens. To this day, it remains one of the top-performing cinemas
            in Bangalore.
          </Typography>
          <br />
          <Typography gutterBottom>
            In addition to its regular movie screenings, Innovative Multiplex is
            also the host of the annual Innovative International Film Festival.
            The festival attracts participation from a variety of countries and
            showcases close to 100 Indian and international films. It draws a
            crowd of well-known celebrities, members of the South Indian film
            industry, and international filmmakers, and is supported by the
            Government of India’s Directorate of Film Festivals.
          </Typography>
        </Box>
        {/* ----- */}
        <Box padding="20px 0 50px 0">
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={4}
                className={classes.whyUsItemContainer}>
                <Box
                  // className={classes.iconContainer}
                  style={{
                    padding: '15px',
                    paddingRight: '35px',
                    height: '80px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#db191c',
                    borderRadius: '10px',
                    width: '120px'
                  }}>
                  <img src={privateIcon} alt="Excellence icon" width="190%" />
                </Box>
                <Box padding="0 0 0 25px">
                  <Typography
                    className={classes.heading}
                    variant="h2"
                    gutterBottom>
                    {'9 Screens'}
                  </Typography>
                  <Typography gutterBottom>
                    {
                      'Enjoy the latest audio and visual technology with a wide selection of films and live events. Comfortable seating and state-of-the-art sound and projection systems make for an unforgettable experience'
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                padding={5}
                className={classes.whyUsItemContainer}>
                <Box className={classes.iconContainer}>
                  <img src={privateIcon} alt="Excellence icons" width="100%" />
                </Box>
                <Box padding="0 0 0 25px">
                  <Typography
                    className={classes.heading}
                    variant="h2"
                    gutterBottom>
                    {'1800 Seating'}
                  </Typography>
                  <Typography gutterBottom>
                    {
                      'Enjoy a comfortable seating experience with our cutting-edge sound and projection systems'
                    }
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* ---- */}
        {/* 
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} style={{ marginTop: '20px' }}>
            <Item
              img={privateIcon}
              title="9 Screens"
              content="Enjoy the latest audio and visual technology with a wide selection of films and live events. Comfortable seating and state-of-the-art sound and projection systems make for an unforgettable experience"
            />
          </Grid>
          <Grid item xs={12} sm={6} style={{ marginTop: '20px' }}>
            <Item
              img={privateIcon}
              title="1800 Seating"
              content="Enjoy a comfortable seating experience with our cutting-edge sound and projection systems"
            />
          </Grid>
        </Grid> */}

        <br />
        <br />
        <br />
        <Box>
          <Typography className={classes.heading} variant="h2" gutterBottom>
            Exquisite Experience for You
          </Typography>
          <br />

          <Grid container spacing={4}>
            {/* ---- */}
            <Grid item xs={12} sm={4}>
              <Item
                img={privateIcon}
                title="Private Screen"
                content="Make your Trip to the Cinema More Enjoyable with the comfort of Private Screening at Innovative Multiplex"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={priminmIcon}
                title="Premium Private Screen"
                content="Sit Back, Relax and Enjoy Exquisite Premium Private Screening at Innovative Multiplex."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={threeDIcon}
                title="3D Movie Screenings"
                content="The 3D Technology that we use is the new generation of entertainment, with crisp, bright, ultra-realistic images."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={soundSystemIcon}
                title="Dolby® Sound System"
                content="Dolby is the global leader in sound technology ensures a premium cinema experience"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={vipIcon}
                title="VIP Gold Lounge"
                content="Enjoy the Luxury of glamorous, modern, and customized entertainment experience."
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={iCafeIcon}
                title="ICafe"
                content="We believe in the pleasure of great service, advance technology and superior innovations."
              />
            </Grid>

            {/* ---- */}
            <Grid item xs={12} sm={4}>
              <Item
                img={privateIcon}
                title="Stand Alone Multiplex"
                content="Our Stand Alone Multiplex boasts a modern and spacious auditorium, equipped with cutting-edge sound and projection technology to enhance your movie-watching experience. Relax in comfortable seating and immerse yourself in the action on the big screen"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={privateIcon}
                title="Easy Access "
                content="Our theatre prioritises ease of access for all guests, ensuring a smooth and enjoyable experience"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={privateIcon}
                title="Best Parking Facilities"
                content="Enjoy hassle-free parking with our ample and conveniently located facilities"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={privateIcon}
                title="Centralised Location "
                content="Experience the ultimate convenience with our centralised location. Easily accessible by public transportation and surrounded by a variety of dining and shopping options, our theatre is the perfect destination for a fun-filled day or night out"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Item
                img={privateIcon}
                title="Great Ambience "
                content="Escape into a world of cinema with our great ambience. From the moment you step inside, you'll be transported to a place of comfort and relaxation. So sit back, relax, and enjoy the show in style"
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default About;
