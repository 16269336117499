import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    color: theme.palette.common.black,
    marginTop: 125,
    height: "55%"
  },
  title: {
    fontSize: '35px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: '25px',
    },
  },
  subTitle: {
    fontSize: '25px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
    },
  },
  description: {
    fontSize: '16px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
    },
  }
}))

const HelpPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg'>
        <Box textAlign="center" marginBottom={5}>
          <Typography gutterBottom variant='h1' className={classes.title}>
            HELP CENTER
          </Typography>
        </Box>

        <Box paddingTop={5}>
          <Typography gutterBottom className={classes.description}>
            <b>Innovative Multiplex</b>.
          </Typography>
          <Typography gutterBottom className={classes.description}>
            <b>Helpline Number: <a href="tel:+919632280000">9632280000</a></b>.
          </Typography>
          <Typography gutterBottom className={classes.description}>
            <b>Helpline Mail: <a href="mailto:innovativemultiplex.ifa@gmail.com">innovativemultiplex.ifa@gmail.com</a></b>.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default HelpPage;