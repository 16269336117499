import {
  GET_PRIVATE_RESERVATIONS,
  GET_RESERVATION_SUGGESTED_SEATS
} from '../types';

const initialState = {
  privatereservation: []
};

const getPrivateReservations = (state, payload) => ({
  ...state,
  privatereservation: payload
});

// const getReservationSuggestedSeats = (state, payload) => ({
//   ...state,
//   suggestedSeats: payload
// });

export default (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_PRIVATE_RESERVATIONS:
      return getPrivateReservations(state, payload);
    // case GET_RESERVATION_SUGGESTED_SEATS:
    //   return getReservationSuggestedSeats(state, payload);
    default:
      return state;
  }
};
