export const textTruncate = (text, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (text.length > length) {
    return text.substring(0, length - ending.length) + ending;
  } else {
    return text;
  }
};
//checks whether the object items contains the search field or not
const itemObjectContains = (data, reg) => {
  console.log('inside item object contains', data);
  let status = false;
  if (data) {
    for (const key in data) {
      // console.log(`${key}: ${user[key]}`);
      if (data[key].match(reg)) {
        status = true;
        break;
      }
    }
  }
  return status;
};

export const match = (term, array, key) => {
  const reg = new RegExp(term.split('').join('.*'), 'i');
  const filterData = key.split(' ');

  // console.log(, filterData);

  return array.filter(item => {
    return filterData.some(value => {
      console.log('this is filter data', item?.[value]);
      if (!item?.[value]) return false;
      return item?.[value] && typeof item?.[value] === 'object'
        ? itemObjectContains(item?.[value], reg)
        : typeof item?.[value] === 'number'
        ? item?.[value].toString().match(reg)
        : item?.[value].match(reg);
    });
  });
  // console.log('this is data from filter', data);
  // return array.filter(item => item[key] && item[key].match(reg));
};
