export default theme => ({
  sectionTitle: {
    fontSize: '25px',
    fontWeight: '600',
    margin: '15px 0px',
    color: 'black',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem'
    }
  },
  h3Tag: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
      margin: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '8px',
      marginLeft: '10px',
      marginRight: '10px'
    }
  },
  button: {
    color: '#dc2627'
  },
  dropdown: {
    fontSize: '1rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px'
    },

    '&::before': {
      borderBottom: 'none'
    },
    '&:hover': {
      borderBottom: 'none'
    }
  },
  inputStyle: {
    border: '1px solid black'
  },

  carousel: {
    marginTop: '8rem'
  },
  stack: {
    marginBottom: '50px'
  },
  container: {
    width: '55%',
    marginTop: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '80%',
      fontSize: '4px',
      marginLeft: '12px'
    }
  },
  textfield: {
    width: '20vw',
    [theme.breakpoints.down('xs')]: {
      width: '34vw',
      paddingTop: '6px'
    }
  },
  btn: {
    width: '150px',
    marginBottom: '30px',
    [theme.breakpoints.down('xs')]: {
      width: '100px',
      marginTop: '15px'
    }
  }
});
