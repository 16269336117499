import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, TextField, Grid, Button, Box } from '@material-ui/core';
import { Paper } from '../../../../../components';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(3)
  },
  paper: { padding: theme.spacing(4) },
  gridContainer: {
    marginTop: theme.spacing(4)
  },
  successInfo: { margin: theme.spacing(3) },
  ignoreButton: {
    marginLeft: theme.spacing(3)
  }
}));

const convertToAlphabet = value => (value + 10).toString(36).toUpperCase();

export default function BookingInvitation(props) {
  const classes = useStyles(props);
  const {
    selectedSeats,
    sendInvitations,
    ignore,
    invitations,
    onSetInvitation,
    onDownloadPDF,
    cinema,
    data
  } = props;
  const history = useHistory()

  // const notValidInvitations = !Object.keys(invitations).length;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center">
          Guest Invitation
        </Typography>
        <Typography
          className={classes.successInfo}
          variant="body1"
          align="center">
          You have successfuly booked your seats.
        </Typography>
        <div style={{display:"flex",gap:10,justifyContent:"center"}}>

        <Box  textAlign="center">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => onDownloadPDF(data)}>
            Download Pass
          </Button>
        </Box>
        <Box  textAlign="center"  >          
          <Button
          style={{background:"#f84464",boxShadow: '0 1px 8px rgba(0,0,0,.16)',
          borderRadius: '8px', border:"none",color:"white"
          }}
            color=""

            variant="text"
            onClick={() => history?.replace("/")}>
            Done
          </Button>
          </Box>
        </div>
      </Paper>
    </div>
  );
}
