import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

const DialogModal = ({ children, isOpen }) => {
  // const [open, setOpen] = useState(false);

  // const onOpenModal = () => setOpen(true);
  // const onCloseModal = () => setOpen(false);

  return (
    <div>
      {/* <button onClick={onOpenModal}>Open modal</button> */}
      <Modal
        styles={{ modalContainer: { background: 'transparent',margin:"auto",borderRadius:"12px",overflow:"hidden" } }}
        open={isOpen}
        // onClose={onCloseModal}
        center={true}
        closeOnOverlayClick={false}
        showCloseIcon={false}
        closeOnEsc={false}>
        {children}
      </Modal>
    </div>
  );
};

export default DialogModal;
