import React, { Component } from 'react';
import { connect } from 'react-redux';
import { register } from '../../../store/actions';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import {
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import styles from './styles';
import FileUpload from '../../../components/FileUpload/FileUpload';
import { useLocation } from 'react-router-dom';

class Register extends Component {
  state = {
    values: {
      name: '',
      email: '',
      phone: '',
      password: '',
      image: null,
      policy: false
    },
    redirectedFromGoogle: false // New state variable to track redirection from Google login

  };

  componentDidUpdate(prevProps) {
    const { isAuthenticated, history } = this.props;
    if (prevProps.isAuthenticated !== isAuthenticated || isAuthenticated)
      history.push('/');
  }
  
 
  componentDidMount() {
    const userData = localStorage.getItem('userData');
    if (userData) {
      const parsedUserData = JSON.parse(userData);
      // console.log("this is user data", parsedUserData);
  
      // Extract the values from the user data
      const { email, displayName, uid } = parsedUserData.user;
      // console.log("this is email data", email, displayName, uid );
      this.setState(prevState => ({
        values: {
          ...prevState.values,
          email: email,
          name: displayName,
          password: uid
        },
        redirectedFromGoogle: parsedUserData.redirectedFromGoogle
      }));
    }
  }
  
  
  

  handleBack = () => {
    const { history } = this.props;
    localStorage.clear();
    history.goBack();
  };
  handleClear = () => {
    // const { history } = this.props;
    localStorage.clear();
    // history.goBack();
  };

  handleFieldChange = (field, value, e) => {
    const newState = { ...this.state };

    newState.values[field] = value;

    this.setState(newState);
  };
  

  handleRegister = () => {
    const newUser = this.state.values;
    // console.log("this is new user data",newUser)
    this.props.register(newUser);
  };

  render() {
    const { classes,location } = this.props;
    const { values,redirectedFromGoogle } = this.state;
  // console.log("this is redirectedFromGoogle",redirectedFromGoogle)
    const isValid = values.policy;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.bgWrapper} item lg={5}>
            <div className={classes.bg} />
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton
                  className={classes.backButton}
                  onClick={this.handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form}>
                  <Typography className={classes.title} variant="h2">
                    Create new account
                  </Typography>
                  <Typography className={classes.subtitle} variant="body1">
                    Use your email to create new account... it's free.
                  </Typography>
                  <div className={classes.fields}>
                  {!redirectedFromGoogle ? (
                    <>
                    <TextField
                      className={classes.textField}
                      label="Full name"
                      name="name"
                      value={values.name}
                      onChange={event =>
                        this.handleFieldChange('name', event.target.value)
                      }
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      label="Email address"
                      name="email"
                      value={values.email}
                      onChange={event =>
                        this.handleFieldChange('email', event.target.value)
                      }
                      variant="outlined"
                    />
                    <TextField
                      type="number"
                      // inputProps={{ maxLength: 10 }}
                      className={classes.textField}
                      label="Mobile Phone (10 digits)"
                      name="phone"
                      value={values.phone}
                      variant="outlined"
                      onChange={event =>
                        this.handleFieldChange(
                          'phone',
                          event.target.value,
                          event
                        )
                      }
                    />
                    <TextField
                      className={classes.textField}
                      label="Password"
                      type="password"
                      value={values.password}
                      variant="outlined"
                      onChange={event =>
                        this.handleFieldChange('password', event.target.value)
                      }
                    />
                    </> 
                  ) :<TextField
                  type="number"
                  // inputProps={{ maxLength: 10 }}
                  className={classes.textField}
                  label="Mobile Phone (10 digits)"
                  name="phone"
                  value={values.phone}
                  variant="outlined"
                  onChange={event =>
                    this.handleFieldChange(
                      'phone',
                      event.target.value,
                      event
                    )
                  }
                /> }
                    <div className={classes.policy}>
                      <Checkbox
                        checked={values.policy}
                        className={classes.policyCheckbox}
                        color="primary"
                        name="policy"
                        onChange={() =>
                          this.handleFieldChange('policy', !values.policy)
                        }
                      />
                      <Typography
                        className={classes.policyText}
                        variant="body1">
                        I have read the &nbsp;
                        <Link className={classes.policyUrl} to="#">
                          Terms and Conditions
                        </Link>
                        .
                      </Typography>
                    </div>
                  </div>

                  <Button
                    className={classes.registerButton}
                    color="primary"
                    disabled={!isValid}
                    onClick={this.handleRegister}
                    size="large"
                    variant="contained">
                    Register now
                  </Button>

                  <Typography className={classes.login} variant="body1">
                    Have an account?{' '}
                    <Link className={classes.loginUrl} to="/login" onClick={this.handleClear}>
                      Login
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

Register.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  register: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.authState.isAuthenticated
});

export default withStyles(styles)(
  connect(mapStateToProps, { register })(Register)
);


