import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  InputBase
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import MovieCardNew from '../MovieCardSimple/MovieCardNew';
import styles from './styles';
import {
  getCinemas,
  getCinemasUserModeling,
  getMovies,
  getReservations,
  getUsers
} from '../../../../store/actions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(styles);
const MovieGrid = ({
  movies = [],
  showtimes = [],
  cinemas = [],
  title,
  to = null,
  showButton = true
}) => {
  const classes = useStyles();
  const dispatcher = useDispatch();
  const [moviebylanguage, setmMoviebylanguage] = useState('allanguage');
  const [Bylanguage, setBylanguage] = useState([]);

  useEffect(() => {
    dispatcher(getMovies(moviebylanguage));
  }, [moviebylanguage]);

  const Unique = (value, index, self) => {
    return self.indexOf(value) === index;
  };
  useEffect(() => {
    //useeefect should not happen if its a language other than all languages
    if (moviebylanguage !== 'allanguage') return;
    //
    let a = movies.map(ind => ind.language);
    let result = a.filter((q, as) => a.indexOf(q) === as);
    let unique = result.filter(Unique);
    unique.unshift('all Language');
    setBylanguage(unique);

    //
  }, [movies]);

  if (!movies.length) return null;

  return (
    <Container maxWidth="lg">
      {title && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography
            className={classes.sectionTitle}
            variant="h2"
            color="inherit">
            {title}
          </Typography>

          <Box>
            <FormControl>
              <Select
                value={moviebylanguage}
                className={classes.dropdown}
                onChange={e => {
                  setmMoviebylanguage(e.target.value);
                }}
                inputProps={{ 'aria-label': 'Without label' }}>
                {Bylanguage.map((item, key) => {
                  return (
                    <MenuItem
                      key={item}
                      value={item === 'all Language' ? 'allanguage' : item}>
                      {item.toUpperCase()}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        </Box>
      )}

      <Grid container spacing={2}>
        {movies.map(movie => (
          <Grid key={movie._id} item xs={12} sm={3}>
            <MovieCardNew
              title={title}
              movie={movie}
              showtimes={showtimes}
              cinemas={cinemas}
            />
          </Grid>
        ))}
      </Grid>

      {to == null
        ? null
        : showButton && (
            <Box padding="20px 0" textAlign="center">
              <Link to={to}>
                <Button className={classes.button}>View All {'>'}</Button>
              </Link>
            </Box>
          )}
    </Container>
  );
};
export default MovieGrid;
