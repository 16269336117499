import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import audienceIcon from '../../../../assets/icons/audience.png';
import excellenceIcon from '../../../../assets/icons/excellence.png';

const WhyUs = ({ classes }) => {
  const Item = ({ img, title, content }) => (
    <Box display="flex" flexDirection="row" alignItems="center" padding={5} className={classes.whyUsItemContainer}>
      <Box className={classes.iconContainer}>
        <img src={img} alt="Excellence icon" width="100%" />
      </Box>
      <Box padding="0 0 0 25px">
        <Typography className={classes.heading} variant='h2' gutterBottom>
          {title}
        </Typography>
        <Typography gutterBottom>
          {content}
        </Typography>
      </Box>
    </Box>
  );


  return (
    <Box>
      <Box>
        <Typography className={classes.heading} variant='h2' gutterBottom>
          Why Us
        </Typography>
        <Typography gutterBottom>
          We offer exclusive entertainment and top class facilities. Come, have an affordable and enjoyable experience with Innovative Multiplex.
        </Typography>
      </Box>

      <Box padding="20px 0 50px 0">
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={6}>
            <Item img={excellenceIcon}
              title="20+ Years of Excellence"
              content="With over and presence in the Film Industry,
                  we take pride in the Establishment of
                  Innovative Multiplex, the first Multiplex of
                  Karnataka in 2003."/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Item img={audienceIcon}
              title="1 lakh peak audience/mo"
              content=" Innovation & Excellence is Our Mantra! With a
                1500 seating capacity, Innovative Multiplex is,
                till today, one of the best-operating screens in
                the city."/>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default WhyUs;