import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import IFALOGO from "../../../../assets/icons/IFA LOGO-Black.png"
const AboutAcademy = ({ classes }) => {
  return (
    <Box padding="50px 0 30px 0">
      <Container maxWidth="lg">
        <Box>
          <Typography className={classes.heading} variant='h2' gutterBottom>
            About Innovative Film Academy
          </Typography>
          <Typography gutterBottom>
            Innovative Film Academy was founded in 2015. It is Innovative Group’s most recent venture with a vision of crafting hands-on experience for the budding film makers.
          </Typography>
          <br />
          <Typography gutterBottom>
            Innovative Film Academy offers a well designed course in Filmmaking with the objective of covering all aspects of film art and film technology. The studies at IFA grooms its students to network and learn the technique of business in the film industry. The Innovative Film Academy hosts one of its flagship centers at the Innovative Multiplex, with state of the art equipped classrooms
          </Typography>
          <br />
        <a href="https://innovativefilmacademy.com/" target="_blank">
        <span>
            <img src={IFALOGO} alt="" height="122px" width="63px" />
          </span>
        </a>
        </Box>
        <br /><br /><br />

        {/* <Box>
          <Typography className={classes.heading} variant='h2' gutterBottom>
            Our Associations
          </Typography>
          <br />

          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={3}>
              <Box className={classes.associationImageContainer}>
                <img src={associationImage1} alt="" width="100%" className={classes.associationImage} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box className={classes.associationImageContainer}>
                <img src={associationImage2} alt="" width="100%" className={classes.associationImage} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box className={classes.associationImageContainer}>
                <img src={associationImage3} alt="" width="100%" className={classes.associationImage} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Box className={classes.associationImageContainer}>
                <img src={associationImage4} alt="" width="100%" className={classes.associationImage} />
              </Box>
            </Grid>
          </Grid>
        </Box> */}
      </Container>
    </Box>
  );
};

export default AboutAcademy;