// import { useEffect } from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { RedButton } from '../../../../theme/buttons';
import styles from './styles';

const useStyles = makeStyles(styles);

const MovieCardHome = props => {
  const classes = useStyles();
  const { image, infoAvailable, siteLink, handleImageClick } = props;
  // console.log("MovieCardHome" , image)

  return (
    <Box>
      <Box style={{ position: 'relative' }}>
        <Box className={classes.bannerImage}>
          <img
            src={image}
            
            alt="imagecrashed"
            width="100%"
            height="100%"
            style={{ borderRadius: '20px', }}
            onClick={() => handleImageClick(siteLink)}
          />
        </Box>
        {infoAvailable && (
          <Box className={classes.infoContainer}>
            <Container maxWidth="lg">
              <Typography
                className={classNames('heading', classes.h2)}
                variant="h2"
                component="h2"
                color="inherit"
              >
                WAKANDA FOREVER
              </Typography>
              <Typography style={{ textTransform: 'Capitalize' }} variant="subtitle2">
                English
              </Typography>

              <Box>
                <br />
                <Typography variant="caption">
                  <span className={classes.showtimes}>12: 00 PM</span>
                  <span className={classes.showtimes}>09: 00 PM</span>
                </Typography>
              </Box>
              <br />
              <Link to={`movie/637b7dfe15302ae64d973b2e`}>
                <RedButton style={{ marginLeft: '0px' }}>Book Now</RedButton>
              </Link>
            </Container>
          </Box>
        )}
      </Box>
    </Box>
  );
};

MovieCardHome.propTypes = {
  image: PropTypes.string.isRequired,
};
export default MovieCardHome;
