import Button from '@material-ui/core/Button';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';

const Default = withStyles(() => ({
  root: {
    color:"black",
    borderRadius: "9px",
    borderColor: "black",
    '&:hover': {
      backgroundColor:"#f9fafc"
    },
  },
}))(Button);

const Red = withStyles(() => ({
  root: {
    color: "white",
    borderRadius: "9px",
    backgroundColor: "#db191c",
    '&:hover': {
      backgroundColor: "#b71c1c",
    },
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));

export const DefaultButton = ({children, style}) => {
  const classes = useStyles();
  return (
    <Default variant="outlined" color="primary" style={style} className={classes.margin}>
      {children}
    </Default>
  );
}

export const RedButton = ({children, style}) => {
  const classes = useStyles();
  return (
    <Red variant="contained" color="primary" style={style} className={classes.margin}>
      {children}
    </Red>
  );
}

