import { Box, Container, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import About from './components/About';
import AboutAcademy from './components/AboutAcademy';
import Banner from './components/Banner';
import WhyUs from './components/WhyUs';
import styles from './styles';

class AboutPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { classes } = this.props;

    return (
      <Box className={classes.root}>
        <Container maxWidth="lg">
          <Banner classes={classes} />
          <br />
          <WhyUs classes={classes} />
        </Container>

        <About classes={classes} />
        <AboutAcademy classes={classes} />
      </Box>
    );
  }
}

AboutPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AboutPage);