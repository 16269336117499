import React, { useCallback, useEffect, useState } from 'react'
import useRazorpay from "react-razorpay";
import Backend_API from '../../../api';
import axios from 'axios';
import { encryptandDecrypt } from '../../../utils/EncrytDecryptData';
import useBooking from './useBooking';
import { useHistory } from 'react-router-dom';
import BookingInvitation from "../BookingPage/components/BookingInvitation/BookingInvitation"
import { Button, Container, Grid, Typography, makeStyles } from '@material-ui/core';
import LoadingDialog from "../../../components/LoadingDialog/DialogModal"
import { SunspotLoader } from "react-awesome-loaders";
import useShowbizz from './useShowbizz';
import Summary from './components/Summary';
import bookingIage from "../../../assets/images/wepik-export-20230604062102jrbO.jpeg"

const successMsg = "Please wait while we process your payment. Do not refresh or navigate away from this page until your payment is successfully completed. This may take a few moments. Thank you for your patience."
const errMsg = "Oops! Something went wrong while booking your ticket. We apologize for the inconvenience. Please try again later or contact our customer support for assistance and your refund will initiated soon .Thank you!"


const useStyles = makeStyles(theme => ({
  container: { height: '100%', paddingTop: theme.spacing(16),
  [theme.breakpoints.down('md')]: {
    width:"100%",
    marginTop: '60px',
    marginBottom:"60px"
  } },
  [theme.breakpoints.down('md')]: {
    root: { height: '100%' },
  }
}));

const MainBooking = (props) => {
  window.scrollTo(0,0)
  const classes = useStyles()
  const history = useHistory()
    const [data,setData] = useState(props?.location?.state)
    const {confirmBooking,isTicketBooked,jsPdfGenerator,handleRefund,handlOrderConfirm} = useBooking(data)
    const [iserrorOcured,setIsErrorOccured] = useState(false)
    const {bookSeatsShowbizz} = useShowbizz()
    const [isLoading,setIsLoading] = useState(false)

    const encoded = props.location?.search?.split("=")[1]

    const confirmOrder = async(orderID) =>{
      const response = await handlOrderConfirm(orderID)
      if(!response?.data) return  history.goBack()
    }
    
    useEffect(() => {
      if(!encoded) return history.goBack()
        const result = encryptandDecrypt.decrypt(encoded)
        // if the user have done the payment already they wont be allowed in the page
        confirmOrder(result?.orderID)
        setData(result)
    },[props?.location?.search])

    const Razorpay = useRazorpay();

    const handlePayment = useCallback((result) => {
      try {


        const options = {
         key: "rzp_live_D9wvDh6h4gIHwg", //live
          // key:"rzp_test_mH6FgR0eAWzZpj",//test
          amount: "100",
          currency: "INR",
          name: 'Innovative Multiplex',
          description: 'Buy Ticket for Movie',
          image: 'https://innovativemultiplex.com/InnovativeMultiplex-Logo.png',
          order_id: result?.orderID,
          handler: async(response) => {
            //process is starting
            setIsLoading(true)
            const returnValue = await bookSeatsShowbizz(result?.convertedSeats,result)
            
            if(!returnValue){
              // if the book my show gives the error the amount is refunded
              handleRefund(response?.razorpay_payment_id)
              return setIsErrorOccured(true)
            } 
            //if the ticket is succesfully booked then stores in the db
            await confirmBooking(response,result)
            // else refunds the amount
            // finally every process is done
            setIsLoading(false)
          },
          prefill: {
              name: result?.name,
              email: result?.email,
              contact: result?.phone
          },
          notes: {
            address: "Razorpay Corporate Office",
          },
          theme: {
            color: "#3399cc",
          },
        };
    
        const rzpay = new Razorpay(options);
        rzpay.open();
      }catch(err){
        console.log(err);
      }
    }, [Razorpay]);



  return (
    <Container maxWidth="lg" className={classes.container} style={{marginBottom:"2rem"}} >
      {
        !isTicketBooked &&
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6} lg={3}  >
          <Summary handlePayment={() => handlePayment(data)} data={data}/>
        </Grid>
        <Grid item xs={0} md={8} lg={9} >
          <div style={{backgroundImage: `url(${bookingIage})`,height:"150px",borderRadius:"8px", backgroundPosition: "center",backgroundSize: "cover",filter: "blur(1px)"
  }}></div>
          {/* <img style={{height:"150px",width:"100%",objectFit:"cover",borderRadius:"8px"}} src={bookingIage} alt="image" /> */}
        </Grid>
      </Grid>
      }
    {/* <button onClick={() =>handlePayment(data)}>Click</button> */}
    {/* if the ticket is in booking process then this happens */}
    <LoadingDialog isOpen={isLoading && !isTicketBooked} >
    <div style={{display:"flex",flexDirection:"column",alignItems:"center",}}>
      <Typography  variant='subtitle1' style={{color:"black",marginBottom:"2rem",textAlign:"center",fontSize:"14px",fontWeight:600}}>{iserrorOcured ? errMsg: successMsg}</Typography>
      { iserrorOcured ?<Button variant='outlined' onClick={() => history.goBack()}>Go Back</Button>
        :<SunspotLoader
    gradientColors={["#6366F1", "#E0E7FF"]}
    shadowColor={"#3730A3"}
    desktopSize={'50px'}
    mobileSize={'30px'}
  />}
    </div>
  </LoadingDialog>
    {
      isTicketBooked &&
      <BookingInvitation data={data} cinema={data?.cinema} onDownloadPDF={jsPdfGenerator} />
    }
    </Container>

  )
}

export default MainBooking