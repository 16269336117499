import React, { lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import Loading from './components/Loading';
import { ProtectedRoute, WithLayoutRoute } from './routers';

import { AdminLayout, PublicLayout } from './layouts';
import AboutPage from './pages/Public/AboutPage/AboutPage';
import ContactPage from './pages/Public/ContactPage/ContactPage';
import DisclaimerPage from './pages/Public/DisclaimerPage/DisclaimerPage';
import FAQPaage from './pages/Public/FaqPage/FaqPage';
import HelpPage from './pages/Public/HelpPage/HelpPage';
import PrivacyPage from './pages/Public/PrivacyPage/PrivacyPage';
import {
  PrivateNowShowing,
  PrivateBookingPage
} from './pages/Public/PrivateBooking/Index';
import MainBooking from './pages/Public/MainBooking/Index';

// Admin
const DashboardPage = lazy(() => import('./pages/Admin/Dashboard'));
const MovieList = lazy(() => import('./pages/Admin/MovieList'));
const CinemaList = lazy(() => import('./pages/Admin/CinemaList'));
const ShowtimeList = lazy(() => import('./pages/Admin/ShowtimeList'));
const ReservationList = lazy(() => import('./pages/Admin/ReservationList'));
const PrivateReservationList = lazy(() =>
  import('./pages/Admin/PrivateReservationList')
);

const User = lazy(() => import('./pages/Admin/User'));
const Account = lazy(() => import('./pages/Admin/Account'));
const Advertisement = lazy(() => import('./pages/Admin/Advertisement/Index'));

// Register - Login
const Register = lazy(() => import('./pages/Public/Register'));
const Login = lazy(() => import('./pages/Public/Login'));

// Public
const HomePage = lazy(() => import('./pages/Public/HomePage'));
const MoviePage = lazy(() => import('./pages/Public/MoviePage'));
const MyDashboard = lazy(() => import('./pages/Public/MyDashboard'));
const MovieCategoryPage = lazy(() =>
  import('./pages/Public/MovieCategoryPage')
);
const CinemasPage = lazy(() => import('./pages/Public/CinemasPage'));
const BookingPage = lazy(() => import('./pages/Public/BookingPage'));

const Checkin = lazy(() => import('./pages/Public/Checkin'));
const Beverages = lazy(() =>
  import('./pages/Public/Snacks&Beverages/Index.js')
);

const Snacks = lazy(() => import('./pages/Admin/snacks&Beverages/index'));

const Routes = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/register" component={Register} />

          <WithLayoutRoute
            exact
            path="/checkin/:reservationId"
            component={Checkin}
            layout={PublicLayout}
          />

          <WithLayoutRoute
            exact
            path="/"
            layout={PublicLayout}
            component={HomePage}
          />
          <WithLayoutRoute
            exact
            path="/mydashboard"
            layout={PublicLayout}
            component={MyDashboard}
          />
          <WithLayoutRoute
            exact
            path="/cinemas"
            layout={PublicLayout}
            component={CinemasPage}
          />
          <WithLayoutRoute
            exact
            path="/snacks&beverages"
            layout={PublicLayout}
            component={Beverages}
          />
          <WithLayoutRoute
            exact
            path="/movie/category/:category"
            layout={PublicLayout}
            component={MovieCategoryPage}
          />
          <WithLayoutRoute
            exact
            path="/movie/:id"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={MoviePage}
          />
          <WithLayoutRoute
            exact
            path="/movie/booking/:id"
            layout={PublicLayout}
            layoutProps={{ withFooter: false }}
            component={BookingPage}
          />
          <WithLayoutRoute
            exact
            path="/private/movies"
            layout={PublicLayout}
            layoutProps={{ withFooter: false }}
            component={PrivateNowShowing}
          />
          <WithLayoutRoute
            exact
            path="/private/movie/booking/:id"
            layout={PublicLayout}
            layoutProps={{ withFooter: false }}
            component={PrivateBookingPage}
          />
          <WithLayoutRoute
            exact
            path="/contact"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={ContactPage}
          />
          <WithLayoutRoute
            exact
            path="/booking/user"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={MainBooking}
          />
          <WithLayoutRoute
            exact
            path="/about"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={AboutPage}
          />
          <WithLayoutRoute
            exact
            path="/privacy-policy"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={PrivacyPage}
          />
          <WithLayoutRoute
            exact
            path="/help-center"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={HelpPage}
          />
          <WithLayoutRoute
            exact
            path="/disclaimer"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={DisclaimerPage}
          />
          <WithLayoutRoute
            exact
            path="/faq"
            layout={PublicLayout}
            layoutProps={{ withFooter: true }}
            component={FAQPaage}
          />

          <ProtectedRoute
            exact
            path="/admin/dashboard"
            layout={AdminLayout}
            component={DashboardPage}
          />
          <ProtectedRoute
            exact
            path="/admin/users"
            layout={AdminLayout}
            component={User}
          />
          <ProtectedRoute
            exact
            path="/admin/advertisement"
            layout={AdminLayout}
            component={Advertisement}
          />
          <ProtectedRoute
            exact
            path="/admin/showtimes"
            layout={AdminLayout}
            component={ShowtimeList}
          />
          <ProtectedRoute
            exact
            path="/admin/reservations"
            layout={AdminLayout}
            component={ReservationList}
          />
          <ProtectedRoute
            exact
            path="/admin/privatereservations"
            layout={AdminLayout}
            component={PrivateReservationList}
          />

          <ProtectedRoute
            exact
            path="/admin/cinemas"
            layout={AdminLayout}
            component={CinemaList}
          />
          <ProtectedRoute
            exact
            path="/admin/movies"
            layout={AdminLayout}
            component={MovieList}
          />
          <ProtectedRoute
            exact
            path="/admin/snacks&beverages"
            layout={AdminLayout}
            component={Snacks}
          />
          <ProtectedRoute
            exact
            path="/admin/account"
            layout={AdminLayout}
            component={Account}
          />
          <Route path="*" component={() => '404 NOT FOUND'} />
        </Switch>
      </Router>
    </Suspense>
  );
};

export default Routes;
