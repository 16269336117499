import { useDispatch, useSelector } from 'react-redux';
import { getCinemas, getShowtimes, setAlert } from '../../../../store/actions';
import usePrivateBooking from '../usePrivateBooking';
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  InputBase,
  TextField,
  Input
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import styles from '../../components/MovieGrid/styles';
import SingleMovieCarousel from '../../components/MovieCarousel/SingleMovieCarousel';
import Backend_API from '../../../../api';

const useStyles = makeStyles(styles);

const Index = props => {
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [showTime, setShowTime] = useState('');
  const [seats, setSeats] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  // window.scroll(0, 0);
  const classes = useStyles();
  const { movies } = usePrivateBooking();
  const dispatch = useDispatch();

  const showtimes = useSelector(state => state?.showtimeState?.showtimes);
  const cinemas = useSelector(state => state?.cinemaState.cinemas);

  if (showtimes.length === 0) dispatch(getShowtimes());
  if (cinemas.length === 0) dispatch(getCinemas());

  const title = 'Private Showing';
  const { randomMovie, comingSoon, nowShowing, suggested } = props;

  const handleSubmit = async event => {
    event.preventDefault();

    // Create an object to hold the form data
    const formData = {
      date,
      showTime,
      seats,
      name,
      email,
      mobile
    };

    try {
      // Send the form data to the server's API endpoint
      const url = Backend_API + '/private/booking';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      dispatch(setAlert('Your enquiry has been Successfully sent', 'success'));

      // Handle the response from the server
      if (response.ok) {
        console.log('Your mail has been Successfully sent');
      } else {
        console.error('Your mail has not been sent');
      }
    } catch (err) {
      console.error('Error sending form data:', err);
    }

    // Here, we're just logging the data to the console for demonstration purposes
    console.log(formData);

    // Clear the form by resetting the state variables to their initial values
    setDate(new Date().toISOString().slice(0, 10));
    setShowTime('');
    setSeats('');
    setName('');
    setEmail('');
    setMobile('');
  };
  const isDisabled = !name || !email || !mobile || !seats || !showTime;

  console.log('this is now showing', nowShowing);

  return (
    <div>
      <SingleMovieCarousel
        carouselClass={classes.carousel}
        title="Banner"
        to="/movie/category/nowShowing"
        movies={nowShowing}
      />
      <p
        style={{
          color: 'black',
          textAlign: 'center',
          marginBottom: '0',
          fontSize: '16px'
        }}
        className={classes.h3Tag}>
        Please fill in your details below, and one of our representatives will
        contact you shortly.
      </p>
      <p
        style={{
          color: 'black',
          textAlign: 'center',
          marginTop: '0',
          fontSize: '14px'
        }}
        className={classes.h3Tag}>
        If you have any questions, don't hesitate to contact us at{' '}
        <a href="tel:+919632280000">+91 9632280000</a> or email us at{' '}
        <a href="mailto:info@innovativemultiplex.com">
          info@innovativemultiplex.com.
        </a>
      </p>

      <Container
        className={classes.container}
        style={{
          // width: '55%',
          marginTop: '20px',
          textAlign: 'center'
        }}>
        <h2 style={{ color: '#3c3c3d', letterSpacing: '2px' }}>
          Private screen booking form
        </h2>
        <form
          style={{
            display: 'flex',
            padding: '15px'
          }}>
          <Grid container spacing={3} style={{ paddingRight: '30px' }}>
            <Grid item xs={12}>
              <TextField
                required
                label="Name"
                className={classes.textfield}
                // style={{ width: '20vw' }}
                value={name}
                onChange={event => {
                  event.preventDefault();
                  setName(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Email"
                type="email"
                // style={{ width: '20vw' }}
                className={classes.textfield}
                value={email}
                onChange={event => {
                  event.preventDefault();
                  setEmail(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Mobile"
                type="tel"
                // style={{ width: '20vw' }}
                className={classes.textfield}
                value={mobile}
                onChange={event => {
                  event.preventDefault();
                  setMobile(event.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Input
                type="date"
                value={date}
                onChange={event => {
                  event.preventDefault();
                  setDate(event.target.value);
                }}
                className={classes.textfield}
                style={{
                  // width: '20vw',
                  height: '44px'
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Preferred Show Time"
                // style={{ width: '20vw' }}
                className={classes.textfield}
                value={showTime}
                onChange={event => {
                  event.preventDefault();
                  setShowTime(event.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                label="Number of Seats"
                type="number"
                // style={{ width: '20vw' }}
                className={classes.textfield}
                value={seats}
                onChange={event => {
                  event.preventDefault();
                  setSeats(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </form>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
          <Button
            type="submit"
            className={classes.btn}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{
              backgroundColor: isDisabled ? 'grey' : '#db191c',
              color: 'white'
              // width: '150px'
            }}
            disabled={isDisabled}>
            Submit
          </Button>
        </Grid>
      </Container>
    </div>
  );
};

export default Index;
