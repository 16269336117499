import axios from 'axios';
import jsPDF from 'jspdf';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Backend_API from '../../../../api';
import {
  getReservations,
  showInvitationForm,
  toggleLoginPopup
} from '../../../../store/actions';

const useRazorpay = (bookingForm, movie) => {
  //change the price
  const [showInvition, setShowInvitation] = useState(false);
  const [QRCode, setQRCode] = useState(null);
  const dispath = useDispatch();
  const isAuth = useSelector(state => state?.authState?.isAuthenticated);
  const showLoginPopup = useSelector(
    state => state.checkoutState.showLoginPopup
  );

  const checkout = async (totalPrice, ticketPrice, cinema) => {
    console.log('this is authentication', isAuth);
    if (!isAuth) return dispath(toggleLoginPopup());

    const token = localStorage.getItem('jwtToken');
    const user = JSON.parse(localStorage.getItem('user'));

    const response = await axios({
      method: 'post',
      url: Backend_API + '/checkoutRazorPay',
      data: {
        // amount: selectedSeats.length * this.state.ticketPrice
        amount: totalPrice
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    }).then(async json => {
      console.log(json.data);

      var options = {
        key: 'rzp_live_D9wvDh6h4gIHwg', // Enter the Key ID generated from the Dashboard
        // key: 'rzp_test_pJUiKUj0SR0Dk7',
        amount: '100', // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: 'INR',
        name: 'Innovative Multiplex',
        description: 'Buy Ticket for Movie',
        image: 'https://innovativemultiplex.com/InnovativeMultiplex-Logo.png',
        order_id: json.data.orderID, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        callback_url: 'http://localhost:8080/reservationsRazorPay',
        prefill: {
          name: user.username,
          email: user.email,
          contact: user.phone
        },
        notes: {
          address: 'Razorpay Corporate Office'
        },
        theme: {
          color: '#3399cc'
        },
        handler: async function(response) {
          const success = await axios({
            method: 'post',
            url: Backend_API + '/reservationsRazorPay',
            data: {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_order_id: response.razorpay_order_id,
              razorpay_signature: response.razorpay_signature,
              date: bookingForm?.selectedDate,
              startAt: bookingForm?.selectedTime,
              seats: [bookingForm?.count],
              ticketPrice: ticketPrice,
              total: totalPrice,
              movieId: movie?._id,
              cinemaId: cinema?._id,
              phone: user.phone,
              name: user?.name,
              movie,
              cinema: cinema.name + ' Screen',
              selectedDate: bookingForm?.selectedDate,
              selectedTime: bookingForm?.selectedTime,
              selectedSeats: ['All'],
              isPrivate: true
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }).then(async json => {
            setQRCode(json.data.QRCode);
            setShowInvitation(true);
            dispath(getReservations());
          });
        }
      };

      let rzp = new window.Razorpay(options);
      rzp.open();
    });
  };
  // to generate the Pdf
  const jsPdfGenerator = cinema => {
    const doc = new jsPDF();
    doc.setFont('helvetica');
    doc.setFontType('bold');
    doc.setFontSize(22);
    doc.text(movie?.title, 20, 20);
    doc.setFontSize(16);
    doc.text(cinema?.name, 20, 30);
    doc.text(
      `Date: ${new Date(
        bookingForm?.selectedDate
      ).toLocaleDateString()} - Time: ${bookingForm?.selectedTime}`,
      20,
      40
    );
    doc.addImage(QRCode, 'JPEG', 15, 40, 100, 100);
    doc.text('Seat Numbers:-', 20, 145);
    doc.setFontType('normal');
    doc.setFontSize(14);
    doc.text('All', 40, 155);
    doc.save(`${movie?.title}-${cinema?.name}.pdf`);
  };
  return { checkout, showInvition, jsPdfGenerator, showLoginPopup };
};

export default useRazorpay;
