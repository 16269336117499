import MomentUtils from '@date-io/moment';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import React, { useState } from 'react';
import { tConvert } from '../../../../utils/timeConvert';
import makeStyles from '@material-ui/styles/makeStyles';
import { weekday } from '../../../../utils/weekdays';
import ticketNotFound from '../../../../assets/animation/134416-ticket-blue.json';
import Lottie from 'lottie-react';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      padding: 0,
      '& .MuiButtonBase-root': {
        // padding: 0,
        // paddingLeft: 10
      },
      '& .MuiInputBase-input': {
        // padding: 15,
        // paddingLeft: 0
      }
    }
  }
});

const LottieAnimation = () => {
  return (
    <Box
      style={{
        position: 'relative',
        top: '-40px',
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      }}>
      <Lottie
        style={{ width: '40%' }}
        animationData={ticketNotFound}
        loop={true}
      />
    </Box>
  );
};
const BookingForm = props => {
  const {
    showtime,
    selectedDate,
    onChangeDate,
    selectedTime,
    onChangeTime,
    selectedMovie,
    uniqueShowTimes,
    cinemaSelected,
    classes
  } = props;
  let data = uniqueShowTimes();
  return (
    <Grid
      container
      spacing={3}
      className={classes.private}
      style={{ marginLeft: '1rem', fontWeight: 600 }}>
      <Grid item xs={12}>
        <Typography variant="h4" style={{ color: 'black' }}>
          Private Booking
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container style={{ gap: '21px' }}>
          {data.length > 0 &&
            data?.map((value, ind) => {
              const date = new Date(value).toDateString().split(' ');
              return (
                <>
                  {new Date().setHours(0, 0, 0, 0) <=
                    new Date(value).setHours(0, 0, 0, 0) && (
                    <div
                      key={ind}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 12
                      }}>
                      <Box
                        onClick={() => onChangeDate(value)}
                        style={{
                          color: value === selectedDate ? 'white' : 'black',
                          width: '52px',
                          background:
                            value === selectedDate ? '#FF0000' : 'white',
                          borderRadius: '46.6667px',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '80px',
                          cursor: 'pointer',
                          boxShadow:
                            '0px 1.86667px 3.73333px rgba(0, 0, 0, 0.12)',
                          transform: value === selectedDate && 'scale(1.2)',
                          transition: 'all 0.3s'
                        }}
                        key={ind}>
                        <Typography variant="subtitle1">{date[0]}</Typography>
                        <Typography variant="subtitle1">{date[2]}</Typography>
                      </Box>
                      {value === selectedDate && (
                        <Box
                          style={{
                            width: '9px',
                            height: '9px',
                            background: '#FF0000',
                            borderRadius: '100%'
                          }}></Box>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </Grid>
      </Grid>

      {selectedDate && (
        <Grid item xs={12} style={{ margin: '1rem 0 0 0' }}>
          <Typography
            style={{ color: 'black', width: '300px', fontWeight: '700' }}
            variant="h6">
            Choose the Time
          </Typography>

          <Grid container style={{ gap: 8, margin: '1rem 0 0 0' }}>
            {showtime.length > 0 ? (
              showtime.map((value, ind) => {
                return (
                  <Grid item xs={4} sm={2} key={ind}>
                    <Button
                      onClick={() => onChangeTime(value?.startAt)}
                      style={{
                        width: '100%',
                        height: '70px',
                        // color: 'white',
                        fontWeight: '700',
                        background:
                          selectedTime === value?.startAt ? 'white' : '#00b8f5',
                        border:
                          selectedTime === value?.startAt
                            ? '1px solid hsla(0,0%,6%,.13)'
                            : '1px solid #00b8f5',
                        fontSize: '16px',
                        color:
                          selectedTime === value?.startAt ? '#21c179' : 'white'
                      }}>
                      {tConvert(value?.startAt)}
                    </Button>
                  </Grid>
                );
              })
            ) : (
              <>
                <LottieAnimation />
                <div
                  style={{
                    color: 'black',
                    fontSize: '18px',
                    margin: 'auto',
                    textTransform: 'capitalize'
                  }}>
                  No Show Times Found for {selectedMovie?.title}{' '}
                </div>
              </>
            )}
          </Grid>
        </Grid>
      )}
      {/* if selectedCinema seats is not found render Illustartion */}
      {selectedDate && selectedTime && !cinemaSelected && (
        <>
          <LottieAnimation />
          <div
            style={{
              color: 'black',
              fontSize: '18px',
              margin: 'auto',
              textTransform: 'capitalize',
              position: 'relative',
              top: '-100px'
            }}>
            No Tickets Found for {selectedMovie?.title}{' '}
          </div>{' '}
        </>
      )}
    </Grid>
  );
};

export default BookingForm;
