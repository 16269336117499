import React from 'react'
import Backend_API from '../../../api';
import configUrl from '../../../utils/BaseUrl';

const useShowbizz = () => {
    const bookSeatsShowbizz = async (seats, data) => {
        console.log(data,"this is seats");
        // const { previousSelectedCinema } = this.state;
        const seatName = data?.cinema?.split(' - ')[1][0];
        const seatSelected =
          seatName === 'S' ? 'SL' : seatName === 'G' ? 'GO' : 'PR';
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        let noOftiket = seats.length;
        let totalLength = 10;
        let leftOverSeat = totalLength - seats.length;
  
        for (let i = 0; leftOverSeat > i; i++) {
          seats.push(0);
        }
        let totalSeat = '';
  
        seats.forEach((val, ind) => {
          totalSeat += val
            ? `&seat${ind + 1}=${
                seatSelected === 'GO' ? val[0] + val[0] : val[0]
              }:${val[1]}`
            : `&seat${ind + 1}=string`;
        });
        const url = Backend_API + configUrl.bookTickets;
  
        // const { phone } = JSON.parse(window.localStorage.getItem('user'));
        const {phone,bookingId,selectedDate,selectedTime }= data
  
        // body:
        // `?BookingId=${this.state.bookingId}&ShowClass=${seatSelected}&NoOfTickets=${noOftiket}&totalSeat=${totalSeat}`; // query
        try {
          const reponseFetched = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
              // 'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: JSON.stringify({
              bookingId,
              seatSelected: seatSelected,
              noOftiket: noOftiket,
              totalSeat: totalSeat,
              showdate: selectedDate,
              showtime: selectedTime,
              mobileNo: phone
            })
          });
  
          const parsed = await reponseFetched.json();
          // if the ticket is bought then return true and make the booking in our db else doesnt make the booking
          if(parsed?.Buy_Out?.ErrMessage?._text ==="Bought") return true
          return false
        } catch (err) {
            console.log(err);
            return false
        }
      };
  return {bookSeatsShowbizz}
}

export default useShowbizz