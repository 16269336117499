import axios from 'axios';
import React, { useState } from 'react'
import Backend_API from '../../../api';
import jsPDF from 'jspdf';



const useBooking = () => {
  const [QRCode,setQRCode] = useState()
  const [isTicketBooked,setIsticketBooked]= useState(false)

  const showInvitationForm = (value) => setIsticketBooked(value)
  const jsPdfGenerator = (data) => {

    const seatsArray = data?.selectedSeats.map(
      seat =>
        `Row: ${(seat[0] + 10)
          .toString(36)
          .toUpperCase()}    Seat Number: ${seat[1] + 1}`
    );

    const seats = seatsArray.toString().replace(/,/g, '\n');

    const doc = new jsPDF();
    doc.setFont('helvetica');
    doc.setFontType('bold');
    doc.setFontSize(22);
    doc.text(data?.movie?.title, 20, 20);
    doc.setFontSize(16);
    doc.text(data?.cinema, 20, 30);
    doc.text(
      `Date: ${new Date(
        data?.selectedDate
      ).toLocaleDateString()} - Time: ${data?.selectedTime}`,
      20,
      40
    );
    doc.addImage(QRCode, 'JPEG', 15, 40, 100, 100);
    doc.text('Seat Numbers:-', 20, 145);
    doc.setFontType('normal');
    doc.setFontSize(14);
    doc.text(seats, 40, 155);
    doc.save(`${data?.movie?.title}-${data?.cinema}.pdf`);
  };
    const confirmBooking = async(response,data) =>{
      const token = localStorage.getItem('jwtToken');

      console.log(token,"this is token");
        return  await axios({
            method: 'post',
            url: Backend_API + '/reservationsRazorPay',
            data: {
              razorpay_payment_id: response?.razorpay_payment_id,
              razorpay_order_id: response?.razorpay_order_id,
              razorpay_signature: response?.razorpay_signature,
              orderID:data?.orderID,
              date: data?.date,
              startAt: data?.startAt,
              seats: data?.seats,
              ticketPrice: data?.ticketPrice,
              total: data?.total,
              movieId: data?.movieId, 
              cinemaId: data?.cinemaId,
              phone: data?.phone,
              // username: user?.username,
              name: data?.name,
              movie:data?.movie,
              cinema: data?.cinema,
              selectedDate:data?.selectedDate,
              selectedTime:data?.selectedTime,
              selectedSeats:data?.selectedSeats
            },
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`
            }
          }).then(async json => {
            console.log(json);
            setQRCode(json.data.QRCode);
            showInvitationForm(true);
            return true
            // getReservations();
            // uncomment from here
            // pdFgenerateAndSend(json.data.QRCode, user);
          }).catch((err) => console.log(err,"this is err"))
    }

    const handleRefund = async(paymentId) =>{
      const token = localStorage.getItem('jwtToken');

      try{

        axios({
          method: 'get',
          url: Backend_API + '/razorpay/refund?paymentId='+paymentId,
          
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
      }catch(err){
        console.log(err);
      }
    }
    const handlOrderConfirm = async(orderId) =>{
      const token = localStorage.getItem('jwtToken');

      try{

        const response = await axios({
          method: 'get',
          url: Backend_API + '/razorpay/order/check?orderId='+orderId,
          
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })
        return response
      }catch(err){
        console.log(err);
      }
    
    }
  return {confirmBooking,isTicketBooked,jsPdfGenerator,handleRefund,handlOrderConfirm}
}

export default useBooking