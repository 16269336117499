import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Backend_API from '../../../api';
import { setAlert } from '../../../store/actions';

const usePrivateBooking = () => {
  const dispatch = useDispatch();

  const [movies, setMovies] = useState([]);

  useEffect(() => {
    getPrivateMovies();
  }, []);
  const getPrivateMovies = async id => {
    try {
      const url = Backend_API + '/private/movies';
      const response = await fetch(url, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });
      const movie = await response.json();
      if (response.ok) {
        setMovies(movie);
      }
    } catch (error) {
      dispatch(setAlert(error.message, 'error', 5000));
    }
  };
  return { movies };
};

export default usePrivateBooking;
