import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    background: theme.palette.background.dark,
    color: theme.palette.common.contrastText
  },
  copyright: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(0.5),
    fontSize: "1rem",
    color: "black",
  },

  menu: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "start",
    },
  },
  buttonContainer: {
    textAlign: "end",
    [theme.breakpoints.down("sm")]: {
      textAlign: "start",
    },
  },

  bottom: {
    marginBottom: theme.spacing(0.5),
    fontSize: "1rem",
    color: "black",
    [theme.breakpoints.down("sm")]: {
      fontSize: ".5rem"
    },
  },
  icon: {
    width: "24px",
    [theme.breakpoints.down("sm")]: {
      width: '8px'
    },
  },
}));
