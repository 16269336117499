import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    color: theme.palette.common.black,
    marginTop: 125,
  },
  title: {
    fontSize: '35px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: '25px',
    },
  },
  subTitle: {
    fontSize: '25px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
    },
  },
  description: {
    fontSize: '16px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
    },
  }
}))

const DisclaimerPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg'>
        <Box textAlign="center" marginBottom={5}>
          <Typography gutterBottom variant='h1' className={classes.title}>
            DISCLAIMER
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.description}>
            Last updated: 2022-12-02
          </Typography>
          <Typography gutterBottom className={classes.subTitle}>
            WEBSITE DISCLAIMER
          </Typography>
          <Typography gutterBottom className={classes.description}>
            The information provided by <b>Innovative Multiplex</b> (“Company”, “we”, “our”, “us”) on <b>innovativemultiplex.com</b> (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site.
          </Typography>

          <Typography variant='button' display='block' gutterBottom className={classes.description}>
            UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY
            LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
            SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE
            OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS
            SOLELY AT YOUR OWN RISK.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            EXTERNAL LINKS DISCLAIMER
          </Typography>

          <Typography gutterBottom className={classes.description}>
            The Site may contain (or you may be sent through the Site) links to other websites
            or content belonging to or originating from third parties or links to websites and
            features. Such external links are not investigated, monitored, or checked for
            accuracy, adequacy, validity, reliability, availability or completeness by us.
          </Typography>

          <Typography gutterBottom className={classes.description}>
            For example, the outlined <a href="https://policymaker.io/disclaimer/" target="_blank" rel="noopener noreferrer">Disclaimer</a>  has been created using <a href="https://policymaker.io/" target="_blank" rel="noopener noreferrer">PolicyMaker.io</a>, a free web application for generating high-quality legal documents. PolicyMaker’s disclaimer generator is an easy-to-use tool for creating an excellent sample Disclaimer template for a website, blog, eCommerce store or app.
          </Typography>

          <Typography variant='button' display='block' gutterBottom className={classes.description}>
            WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
            FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY
            THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR
            FEATURE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A
            PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
            TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            ERRORS AND OMISSIONS DISCLAIMER
          </Typography>

          <Typography gutterBottom className={classes.description}>
            While we have made every attempt to ensure that the information contained in this
            site has been obtained from reliable sources, Innovative Multiplex is not responsible
            for any errors or omissions or for the results obtained from the use of this
            information. All information in this site is provided “as is”, with no guarantee of
            completeness, accuracy, timeliness or of the results obtained from the use of this
            information, and without warranty of any kind, express or implied, including, but
            not limited to warranties of performance, merchantability, and fitness for a
            particular purpose.
          </Typography>

          <Typography gutterBottom className={classes.description}>
            In no event will Innovative Multiplex, its related partnerships or corporations, or
            the partners, agents or employees thereof be liable to you or anyone else for any
            decision made or action taken in reliance on the information in this Site or for any
            consequential, special or similar damages, even if advised of the possibility of such
            damages.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            LOGOS AND TRADEMARKS DISCLAIMER
          </Typography>

          <Typography gutterBottom className={classes.description}>
            All logos and trademarks of third parties referenced on innovativemultiplex.com
            are the trademarks and logos of their respective owners. Any inclusion of such
            trademarks or logos does not imply or constitute any approval, endorsement or
            sponsorship of Innovative Multiplex by such owners.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            CONTACT US
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Should you have any feedback, comments, requests for technical support or other
            inquiries, please contact us by email: <a href="mailto:innovativemultiplex.ifa@gmail.com">innovativemultiplex.ifa@gmail.com</a>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default DisclaimerPage;