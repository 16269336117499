import {
  GET_PRIVATE_RESERVATIONS,
  GET_RESERVATION_SUGGESTED_SEATS
} from '../types';
import { setAlert } from './alert';
import Backend_API from '../../api';

export const getPrivateReservations = () => async dispatch => {
  try {
    const token = localStorage.getItem('jwtToken');
    const url = Backend_API + '/private/booking/list';
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    const privatereservation = await response.json();
    if (response.ok) {
      dispatch({ type: GET_PRIVATE_RESERVATIONS, payload: privatereservation });
    }
  } catch (error) {
    dispatch(setAlert(error.message, 'error', 5000));
  }
};
