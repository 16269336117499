import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';

const BookingCheckout = props => {
  const { count, cinema, onchangeCount, checkout, classes } = props;
  console.log('this is cinema', cinema);
  return (
    <>
      <Grid container style={{ marginTop: '.9rem' }}>
        <Grid item xs={12}>
          <Box
            style={{
              background: '#10101021',
              height: '1px',
              width: '100%'
            }}></Box>

          <Box
            style={{
              width: '70%',
              margin: 'auto',
              padding: '24px 0',
              justifyContent: 'space-between',
              alignItems: 'center',
              display: 'flex'
            }} className={classes.checkout}>
            <Box className={classes.price} style={{ display: 'flex', gap: '3rem' }}>
              <Box
                style={{
                  padding: '1px 30px',
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  border: '1px solid #E3E3E3',
                  borderRadius: '50px',
                  width: '150px'
                }}>
                <button
                  onClick={() => {
                    if (count <= 2) return;
                    onchangeCount(false);
                  }}
                  style={{
                    color: 'black',
                    background: 'none',
                    outline: 'none',
                    border: 'none',
                    fontSize: '27px',
                    fontWeight: '600',
                    cursor: 'pointer'
                  }}>
                  -
                </button>
                <Typography
                  variant="subtitle1"
                  style={{ color: 'black', fontSize: '18px' }}>
                  {count}
                </Typography>
                <button
                  onClick={() => {
                    if (count >= cinema?.seatsAvailable) return;
                    onchangeCount(true);
                  }}
                  style={{
                    color: '#F5A623',
                    background: 'none',
                    outline: 'none',
                    border: 'none',
                    fontSize: '27px',
                    fontWeight: '600',
                    cursor: 'pointer'
                  }}>
                  +
                </button>
              </Box>
              <Box>
                {/*  total price and ticket * price */}
                <Typography
                  variant="subtitle1"
                  style={{
                    color: '#101010',
                    fontSize: '18px',
                    fontWeight: '600'
                  }}>
                  ₹{count * cinema?.ticketPrice}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'hsla(0,0%,6%,.54)',
                    fontSize: '14px'
                  }}>{`Tickets ${count} * ${cinema?.ticketPrice}`}</Typography>
              </Box>
            </Box>
            <Box>
              <Button
                onClick={() =>
                  checkout(
                    cinema?.ticketPrice * count,
                    cinema?.ticketPrice,
                    cinema
                  )
                }
                variant="contained"
                style={{
                  fontWeight: '600',
                  borderRadius: '8px',
                  background: '#00baf2',
                  color: 'white',
                  padding: '12px 30px'
                }}>
                Book Tickets
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BookingCheckout;
