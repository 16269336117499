import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { removeDuplicates } from '../../../../utils/removeDuplicates';
import styles from './styles';
import '../../components/MovieCardSimple/style.css';
import { DefaultButton, RedButton } from '../../../../theme/buttons';
import { isLoggedIn } from '../../../../utils';
const useStyles = makeStyles(styles);

const MovieCardNew = props => {
  // const dispatch = useDispatch()
  const history = useHistory();
  const classes = useStyles();
  const {
    title,
    movie,
    cinemas,
    showtimes,
    isAuth,
    showLoginPopup,
    privateShow
  } = props;
  const [movieTimes, setMovieTimes] = useState([]);

  const [isHovering, setHovering] = useState(false);

  const handleMouseOver = () => {
    setHovering(true);
  };
  const handleMouseOut = () => {
    setHovering(false);
  };

  useEffect(() => {
    if (showtimes.length > 0) {
      const findMovies = showtimes.filter(
        showtime => showtime.movieId === movie._id
      );
      setMovieTimes(removeDuplicates(findMovies, 'startAt'));
    }
  }, [movie._id, showtimes]);

  const check = () => {
    window.location.href = '/MovieCarousel.js';
  };
  const todayDate = new Date();
  const calDate = new Date(movie.releaseDate);
  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Link
          to={
            privateShow
              ? `/movie/booking/${movie._id}?private=true`
              : `/movie/${movie._id}`
          }>
          <Box
            style={{ position: 'relative' }}
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}>
            <Box
              className={classes.movieImage}
              id={isHovering ? 'hoverone' : ''}
              style={{ background: `url(${movie.image})` }}></Box>

            <Box className={classes.movieInfoContainer}>
              <Box height="86%" display="flex" alignItems="flex-end">
                <Typography
                  className={classNames('heading', classes.movieTitle)}
                  variant="h6"
                  component="h6"
                  color="inherit">
                  {movie.title}
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-end" width="80px">
                <Typography className={classes.language} variant="subtitle2">
                  {movie.language}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Link>

        {isHovering && (
          <Box
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseOut}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'absolute',
              bottom: '-4px',
              padding: '10px',
              cursor: 'pointer',
              height: '50px'
            }}>
            <a
              className="LinkTrailer"
              href={movie.trailer}
              target="_blank"
              style={{
                color: 'white',
                textDecoration: 'underline',
                fontSize: '13px',
                fontFamily: 'sans-serif',
                fontWeight: 600
                // width:"100%"
              }}>
              Watch Trailer
            </a>
            {calDate < todayDate && (
              <a
                //onClick={handleBooking}
                href={
                  privateShow
                    ? `/#/private/movie/booking/${movie._id}`
                    : `/#/movie/booking/${movie._id}`
                }
                style={{ textDecoration: 'none' }}>
                <RedButton style={{ marginRight: '0px' }}>Book Now</RedButton>
              </a>
            )}
            {/* {new Date(movie.releaseDate ) <  todayDate  &&   <a onClick={handleBooking}>
                       <RedButton>
                        Book Now
                      </RedButton> 
                    </a>
                    } */}
          </Box>
        )}
      </Box>
    </>
  );
};

MovieCardNew.propTypes = {
  movie: PropTypes.object.isRequired
};

export default MovieCardNew;
