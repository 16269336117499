import React from 'react';
import useBooking from './useBooking';
import BookingForm from './BookingForm';
import { useParams } from 'react-router-dom';
import { Container, Grid } from '@material-ui/core';
import styles from '../../BookingPage/styles';
import MovieInfo from '../../BookingPage/components/MovieInfo/MovieInfo';
import BookingSeats from './BookingSeats';
import BookingCheckout from './BookingCheckout';
import useRazorpay from './useRazorpay';
import BookingInvitation from './BookingInvitation';
import { ResponsiveDialog } from '../../../../components';
import LoginForm from '../../Login/components/LoginForm';
import { useDispatch } from 'react-redux';
import { toggleLoginPopup } from '../../../../store/actions';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  private: {
    marginTop: '8rem'
  },
  [theme.breakpoints.down('md')]: {
    root: {
      marginTop: '9rem'
    },
    private: {
      marginTop: '1rem'
    },
    checkout: {
      flexDirection: 'column'
    },
    price: {
      flexDirection: 'column',
    }
  }
}));

const Index = () => {
  let classes = useStyles();
  const { id } = useParams();

  const {
    showtime,
    bookingForm,
    onChangeDate,
    onChangeTime,
    movie,
    uniqueShowTimes,
    cinemaSelected,
    onchangeCount
  } = useBooking(id);

  const {
    checkout,
    showInvition,
    jsPdfGenerator,
    showLoginPopup
  } = useRazorpay(bookingForm, movie);

  const dispatch = useDispatch();

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Grid container spacing={2} style={{ height: '100%', height: '100vh' }}>
        <MovieInfo movie={movie} />
        <Grid item lg={9} xs={12} md={12}>
          {!showInvition && (
            <BookingForm
              showtime={showtime}
              selectedDate={bookingForm?.selectedDate}
              onChangeDate={onChangeDate}
              selectedTime={bookingForm?.selectedTime}
              onChangeTime={onChangeTime}
              selectedMovie={movie}
              uniqueShowTimes={uniqueShowTimes}
              cinemaSelected={cinemaSelected}
              classes={classes}
            />
          )}
          {!showInvition &&
            bookingForm?.selectedDate &&
            bookingForm?.selectedTime &&
            cinemaSelected?.seats && (
              <>
                <BookingSeats cinema={cinemaSelected} />
                <BookingCheckout
                  count={bookingForm?.count}
                  cinema={cinemaSelected}
                  onchangeCount={onchangeCount}
                  checkout={checkout}
                  classes={classes}
                />
              </>
            )}
          {showInvition && (
            <BookingInvitation
              cinema={cinemaSelected}
              onDownloadPDF={jsPdfGenerator}
            />
          )}
        </Grid>
      </Grid>
      <ResponsiveDialog
        id="Edit-cinema"
        open={showLoginPopup}
        handleClose={() => dispatch(toggleLoginPopup())}
        maxWidth="sm">
        <LoginForm />
      </ResponsiveDialog>
    </Container>
  );
};

export default Index;
