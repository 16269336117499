import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React, { useEffect } from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.paper,
    color: theme.palette.common.black,
    marginTop: 125,
  },
  title: {
    fontSize: '35px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: '25px',
    },
  },
  subTitle: {
    fontSize: '25px',
    fontWeight: "600",
    [theme.breakpoints.down("sm")]: {
      fontSize: '18px',
    },
  },
  description: {
    fontSize: '16px',
    [theme.breakpoints.down("sm")]: {
      fontSize: '12px',
    },
  }
}))

const DisclaimerPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg'>
        <Box textAlign="center" marginBottom={5}>
          <Typography gutterBottom variant='h1' className={classes.title}>
            INNOVATIVE MULTIPLEX FAQ’S
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            How do I book my tickets online?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            4 Simple Steps To Buy Tickets Online.
          </Typography>

          <Box>
            <ul>
              <li>
                <Typography gutterBottom className={classes.description}>
                  Click on MOVIES subsection NOW PLAYING You can click on the
                  movie names to view details.
                </Typography>
              </li>
              <li>
                <Typography gutterBottom className={classes.description}>
                  From the top band select BOOK NOW, preferred class (gold or silver),
                  date, time and number of seats.

                </Typography>
              </li>
              <li>
                <Typography gutterBottom className={classes.description}>
                  Enter your payment mode details and your booking is complete!
                </Typography>
              </li>
              <li>
                <Typography gutterBottom className={classes.description}>
                  NOTE your Transaction ID. It must be mentioned at the time of ticket
                  collection at the cinema counter. Do Note: Tickets must be booked at least 2
                  hours before the show
                </Typography>

              </li>
            </ul>
          </Box>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Charges For Booking Tickets Online?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Nominal amount per ticket as convenience fee is charged for Innovative Multiplex
            Cinema. You have to collect your tickets from the booking counter prior to the show
            using your Transaction ID & Credit Card used for the same.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Can we carry any belongings?
          </Typography>

          <Typography gutterBottom className={classes.description} style={{ color: "red" }}>
            <i>Please do not carry any camera, laptop, electronic items, shopping bags, outside eatables,
              chewing gum, helmet, cigarettes and other tobacco items inside the cinema premises as
              these are strictly NOT allowed.</i>
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Can I Book Tickets In Advance?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            YES, for movies that will start playing the following week (from Friday). Go to the
            MOVIES section, click on your preferred show timing for a particular movie to start
            the booking process.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            What methods of payment are accepted online?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            innovativemultiplex.com accepts internet banking along with all major credit and
            debit cards issued by Visa, MasterCard and AMEX. We also accept payment through
            UPI. When collecting the tickets from the Box office/Internet Kiosk, the card holder
            must be present with the card used to make the booking
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Once I have booked tickets online, how do I collect them?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            You can collect your tickets from the Box Office or ticket kiosk at the cinema you
            have booked tickets for. Please remember to bring your Credit / Debit card with you
            ensuring it is the one used to make the purchase. For security reasons, the card
            holder MUST be present for the tickets to be collected at the cinema.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Can I Cancel Tickets Booked Online And Get A Refund?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            There will be no refund on cancellations.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Can I cancel only part of the tickets?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Partial cancellation is not allowed. Complete transaction will be cancelled.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            When will the refund be processed?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Refunds will be processed within 7 working days.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            If I am running late for the film will my seat be held?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Once your purchase has been confirmed, your admission is held and will not be
            sold to another customer.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            Is the cancellation facility available on tickets bought from Cinema?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            No.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography gutterBottom className={classes.subTitle}>
            How can I contact Innovative Multiplex?
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Please feel free to e-mail us at <a href="mailto:innovativemultiplex.ifa@gmail.com">innovativemultiplex.ifa@gmail.com</a> for all your queries and suggestions.
          </Typography>
        </Box>

        <Box marginBottom={3}>
          <Typography variant="button" display="block" gutterBottom className={classes.subTitle}>
            REFUND POLICY
          </Typography>

          <Typography gutterBottom className={classes.description}>
            Refunds will be processed but Innovative multiplex only in the following circumstances :
          </Typography>

          <Box paddingLeft={4}>
            <ol>
              <li>
                <Typography gutterBottom className={classes.description}>
                  Less than 10 have booked for a show.
                </Typography>
              </li>
              <li>
                <Typography gutterBottom className={classes.description}>
                  Emergency (includes fire & medical), Natural causes and technical issues involving
                  equipment
                </Typography>
              </li>
            </ol>
          </Box>
        </Box>

        <Box marginBottom={3}>
          <Typography variant="button" display="block" gutterBottom className={classes.subTitle}>
            PRICING
          </Typography>

          <Typography gutterBottom className={classes.description}>
            prices may differ on weekdays, weekends special days and special movies.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default DisclaimerPage;