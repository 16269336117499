export default theme => ({
  intro: {
    fontSize: "40px",
    fontWeight: "600",
  },
  title: {
    fontSize: "25px",
    fontWeight: "600",
    margin: "15px 0px"
  },
  button: {
    color: "#dc2627"
  }
})