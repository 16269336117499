import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import bannerImage from '../../../../assets/images/Images.png';

const Banner = ({ classes }) => {
  return (
    <Box>
      <Grid container spacing={5} alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box display="flex" direction="row" alignItems="center" marginTop="44px">
            <Typography className={classes.redText} variant="button" display="block" gutterBottom>
              EXPERIENCE THE THRILL OF CINEMA
            </Typography>
            <Box flexGrow={1}>
              <Box className={classes.divider} />
            </Box>
          </Box>

          <Box>
            <Typography className={classes.heading} variant='h2'>
              Transforming and Creating<br />The Best Entertainment <br /> Destination
            </Typography>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} spacing={5}>
          <Box className={classes.bannerImage}>
            <img src={bannerImage} alt="images" width={"80%"} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Banner;