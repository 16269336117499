import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
console.log('inside proivate route');

const ProtectedRoute = ({
  layout: Layout,
  component: Component,
  isAuthenticated,
  ...rest
}) => {
  // console.log('this is', isAuthenticated);
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Layout>
            <Component {...props} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  isAuthenticated: PropTypes.bool
};
ProtectedRoute.defaultProps = {
  isAuthenticated: false
};
const mapStateToProps = state => ({
  isAuthenticated: state.authState.isAuthenticated
});
export default connect(mapStateToProps)(ProtectedRoute);
