import { Box, Button, Container, Grid, makeStyles, Paper, Typography, withStyles } from '@material-ui/core';
import CallIcon from '@material-ui/icons/Call';
import EmailIcon from '@material-ui/icons/Email';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import React, { Fragment, useEffect } from 'react';
import bgImage from '../../../assets/images/fotovibez2.jpg';
import { CustomInput } from '../../../utils/Inputs';
import styles from './styles';

const useStyles = makeStyles(styles);

const RedButton = withStyles(() => ({
  root: {
    color: "white",
    borderRadius: "9px",
    backgroundColor: "#db191c",
    '&:hover': {
      backgroundColor: "#b71c1c",
    },
  },
}))(Button);

const ContactPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  return (
    <Fragment>


      <Box style={{ background: `url(${bgImage})`, backgroundPosition: "center", textAlign: "center", marginTop: "125px" }}>
        <Box style={{ background: "#00000080", padding: "70px 20px" }}>
          <Typography variant='h2' className={classes.intro}>
            Get In Touch
          </Typography>
          <Typography variant='subtitle1' className='description'>
            We'd love to hear from you. Here's how you can reach us
          </Typography>
        </Box>
      </Box>

      <Box style={{ margin: "50px 0px" }}>
        <Container maxWidth="lg">
          <Grid container spacing={5} alignItems="center">
            <Grid item xs={12} sm={6}>
              <Paper style={{ background: "#c6ddff" }}>
                <Box padding={3}>
                  <Typography className={classes.title} variant="h4">
                    Contact Form
                  </Typography>

                  <form onSubmit={() => console.log('form submitted')}>
                    <CustomInput label="Full Name" variant="outlined" fullWidth size="small" type="text" required
                    />
                    <br /> <br />
                    <CustomInput label="Mobile Number" variant="outlined" fullWidth size="small" type="number" required
                    />
                    <br /> <br />
                    <CustomInput label="Email Id" variant="outlined" fullWidth size="small" type="email" required
                    />
                    <br /> <br />
                    <CustomInput label="Type your message here..." variant="outlined" fullWidth type="text" size="small" required multiline
                      rows={3} rowsMax={3}
                    />
                    <br /> <br /> <br />
                    <RedButton type="submit">
                      Send Message
                    </RedButton>

                  </form>
                  <br />
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper style={{ background: "#c6ddff" }}>
                <Box padding={3}>
                  <Typography className={classes.title} variant="h4">
                    Contact Information
                  </Typography>

                  <Box>
                    <Box display="flex" alignItems="center">
                      <LocationOnIcon style={{ color: "#db191c", marginRight: '5px' }} />
                      <Typography>
                        #135, Innovative Multiplex Marathahalli,
                        <br />
                        Bangalore, Karnataka 560037
                      </Typography>
                    </Box>
                    <br />
                    <Box display="flex" alignItems="center">
                      <EmailIcon style={{ color: "#db191c", marginRight: '5px' }} />
                      <Typography>
                        info@innovativemultiplex.com
                      </Typography>
                    </Box>
                    <br />
                    <Box display="flex" alignItems="center">
                      <CallIcon style={{ color: "#db191c", marginRight: '5px' }} />
                      <Typography>
                        +91 9632280000
                      </Typography>
                    </Box>
                  </Box>

                  <br />
                  <Box>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1944.1565912788396!2d77.69701831744385!3d12.9518006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae132afb1ce231%3A0x4f9ed5a8c901a2db!2sInnovative%20Multiplex!5e0!3m2!1sen!2sbd!4v1669695830515!5m2!1sen!2sbd" width="100%" height="265px" style={{ border: "0px" }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>

        </Container>
      </Box>
    </Fragment>
  );
};

export default ContactPage;