import { Grid, withStyles } from '@material-ui/core';

import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  getCinemas,
  getMovies,
  getMovieSuggestion,
  getShowtimes
} from '../../../store/actions';
import SingleMovieCarousel from '../components/MovieCarousel/SingleMovieCarousel';
import MovieGrid from '../components/MovieGrid/MovieGrid';
import styles from './styles';
import ExclusiveCard from '../components/ExclusiveCard';

class HomePage extends Component {
  componentDidMount() {
    const {
      movies,
      cinemas,
      showtimes,
      suggested,
      getMovies,
      getCinemas,
      getShowtimes,
      getMovieSuggestion,
      user
    } = this.props;
    window.scrollTo(0, 0);

    if (!movies.length) getMovies();
    if (!cinemas.length) getCinemas();
    if (!showtimes.length) getShowtimes();
    if (user) {
      if (!suggested.length) getMovieSuggestion(user.username);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.props.user &&
        this.props.getMovieSuggestion(this.props.user.username);
    }
  }

  render() {
    const {
      classes,
      randomMovie,
      comingSoon,
      nowShowing,
      suggested,
      showtimes,
      cinemas
    } = this.props;


    return (
      <Fragment>
        <br /> <br /> <br /> <br /> <br /> <br /> 
        <SingleMovieCarousel
          carouselClass={classes.carousel}
          title="Banner"
          to="/movie/category/nowShowing"
          movies={nowShowing}
          showtimes={showtimes}
          cinemas={cinemas}
        />
        {/* <OverView/> */}
        <MovieGrid
          carouselClass={classes.carousel}
          title="Suggested for you"
          movies={suggested.slice(0, 8)}
        />
        <MovieGrid
          carouselClass={classes.carousel}
          title="Now Showing"
          to="/movie/category/nowShowing"
          movies={nowShowing.slice(0, 8)}
          showtimes={showtimes}
          cinemas={cinemas}
        />
        <ExclusiveCard />
        <MovieGrid
          carouselClass={classes.carousel}
          title="Coming Soon"
          to="/movie/category/comingSoon"
          movies={comingSoon.slice(0, 8)}
        />
        {false && (
          <Grid container style={{ height: 500 }}>
            <Grid item xs={7} style={{ background: '#131334' }}></Grid>
            <Grid item xs={5} style={{ background: '#010025' }}></Grid>
          </Grid>
        )}
      </Fragment>
    );
  }
}

HomePage.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  movies: PropTypes.array.isRequired,
  latestMovies: PropTypes.array.isRequired,
  cinemas: PropTypes.array.isRequired
};

const mapStateToProps = ({
  movieState,
  showtimeState,
  authState,
  cinemaState
}) => ({
  movies: movieState.movies,
  randomMovie: movieState.randomMovie,
  latestMovies: movieState.latestMovies,
  comingSoon: movieState.comingSoon,
  nowShowing: movieState.nowShowing,
  showtimes: showtimeState.showtimes,
  suggested: movieState.suggested,
  user: authState.user,
  cinemas: cinemaState.cinemas
});

const mapDispatchToProps = {
  getMovies,
  getShowtimes,
  getMovieSuggestion,
  getCinemas
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(HomePage));
