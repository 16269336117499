import { Box, Container, InputBase, withStyles } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import classnames from 'classnames';
import React, { Component, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logout, toggleLoginPopup } from '../../../../store/actions';
import { RedButton } from '../../../../theme/buttons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';

// Component styles
import styles from './styles';
import Typography from '../../../../theme/typography';
import { ExitToApp } from '@material-ui/icons';

class Navbar extends Component {
  state = {
    showMenu: false,
    scrollPos: window.pageYOffset,
    userDetails: null,
    ispopUp: false
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    const userData = window.localStorage.getItem('user');
    const usersData = JSON.parse(userData);
    this.setState({
      userDetails: usersData
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      scrollPos: window.pageYOffset
    });
  };

  render() {
    // console.log(this.state.userDetails);
    // useEffect(() => {
    // }, []);
    const { showMenu, scrollPos } = this.state;
    const { classes, isAuth, user, logout, toggleLoginPopup } = this.props;
    const handleLogin = () => {
      console.log('movies data');
    };
    return (
      <Fragment>
        <nav
          className={classnames({
            [classes.navbar]: true,
            [classes.navbarColor]: scrollPos >= 0
          })}>
          <Container maxWidth="lg">
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center">
              <Link className={classes.logoLink} to="/">
                <img
                  src="/InnovativeMultiplex-Logo.png"
                  alt="Innovative Multiplex Logo"
                  className={classes.logoImage}
                  height="80px"
                />
              </Link>

              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  onChange={handleLogin}
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput
                  }}
                  inputProps={{ 'aria-label': 'search' }}
                />
              </div>

              <div className={classes.navLinks}>
                <Link className={classes.navLink} to="/">
                  Home
                </Link>
                <Link
                  className={classes.navLink}
                  to="/movie/category/nowShowing">
                  Now Showing
                </Link>
                <Link
                  className={classes.navLink}
                  to="/movie/category/comingSoon">
                  Coming Soon
                </Link>
                <Link className={classes.navLink} to="/private/movies">
                  Private Booking
                </Link>
                <Link className={classes.navLink} to="/about">
                  About Us
                </Link>
              </div>

              {/* <div className={classes.navAccount}>
                <UserPopover logout={logout}>
                  <List component="nav">
                    {user && (
                      <ListItem>
                        <Link
                          className={classes.navLink}
                          to={
                            user.role !== 'guest'
                              ? '/admin/dashboard'
                              : '/mydashboard'
                          }>
                          Dashboard
                        </Link>
                      </ListItem>
                    )}

                    {isAuth ? (
                      <ListItem>
                        <Link className={classes.navLink} onClick={logout} to="/">
                          Logout
                        </Link>
                      </ListItem>
                    ) : (
                      <ListItem>
                        <Link className={classes.navLink} to="/login">
                          Login
                        </Link>
                      </ListItem>
                    )}
                  </List>
                </UserPopover>
              </div> */}

              <div className={classes.navAccount} style={{visibility:"hidden"}}>
                <Link
                  to={'/movie/category/nowShowing'}
                  style={{ textDecoration: 'none' }}>
                  <RedButton style={{ marginRight: '0px', marginLeft: '0px' }}>
                    Book Now
                  </RedButton>
                </Link>
              </div>
              {/* this is the profile Icon */}
              {this?.state?.userDetails ? (
                <PopupState variant="popover" popupId="demo-popup-popover">
                  {popupState => (
                    <div>
                      <Box
                        style={{
                          color: 'black',
                          width: '50px',
                          height: '50px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          cursor: 'pointer'
                        }}
                        color="primary"
                        {...bindTrigger(popupState)}>
                        <AccountCircleIcon fontSize="large" />
                      </Box>
                      <Popover
                        {...bindPopover(popupState)}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center'
                        }}>
                        <Box
                          p={2}
                          style={{
                            height: '120px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 4
                          }}>
                          <Box
                            style={{
                              color: 'black',
                              fontWeight: '600',
                              fontSize: '18px'
                            }}>
                            {this?.state?.userDetails?.name}
                          </Box>
                          <Box
                            style={{
                              color: 'black',
                              fontWeight: '400',
                              fontSize: '16px'
                            }}>
                            {this?.state?.userDetails?.email}
                          </Box>
                          <Box
                            style={{
                              color: 'black',
                              fontWeight: '400',
                              fontSize: '16px'
                            }}>
                            {this?.state?.userDetails?.phone}
                          </Box>
                          <Box
                            sx={{ cursor: 'pointer' }}
                            onClick={() => {
                              window.localStorage.clear();
                              window.location.reload();
                            }}>
                            <ExitToApp fontSize="large" />
                          </Box>
                        </Box>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              ) : (
                <Box style={{ marginLeft: '1rem' }}>
                  <Link to={'/login'}>
                    <Button style={{ fontWeight: '600', fontSize: '14px' }}>
                      Login
                    </Button>
                  </Link>
                </Box>
              )}

              <div className={classes.navMobile}>
                <div
                  className={classes.navIcon}
                  onClick={() =>
                    this.setState({ showMenu: !this.state.showMenu })
                  }>
                  <div
                    className={classnames(
                      classes.navIconLine,
                      classes.navIconLine__left
                    )}
                  />
                  <div className={classes.navIconLine} />
                  <div
                    className={classnames(
                      classes.navIconLine,
                      classes.navIconLine__right
                    )}
                  />
                </div>
              </div>
            </Box>
          </Container>
        </nav>

        <div
          className={classnames({
            [classes.navActive]: showMenu,
            [classes.nav]: true
          })}>
          <div className={classes.navContent}>
            <div className={classes.currentPageShadow}>Movies</div>
            <ul
              className={classes.innerNav}
              onClick={() => this.setState({ showMenu: !this.state.showMenu })}>
              <li className={classes.innerNavListItem}>
                <Link className={classes.innerNavLink} to="/about">
                  About Us
                </Link>
              </li>
              <li className={classes.innerNavListItem}>
                <Link
                  className={classes.innerNavLink}
                  to="/movie/category/nowShowing">
                  Now Showing
                </Link>
              </li>
              <li className={classes.innerNavListItem}>
                <Link className={classes.innerNavLink} to="/snacks&beverages">
                  Snacks
                </Link>
              </li>
              <li className={classes.innerNavListItem}>
                <Link className={classes.innerNavLink} to="/private/movies">
                  Private Booking
                </Link>
              </li>
              <li className={classes.innerNavListItem}>
                <Link
                  className={classes.innerNavLink}
                  to="/movie/category/comingSoon">
                  Coming Soon
                </Link>
              </li>
              <li className={classes.innerNavListItem}>
                <Link className={classes.innerNavLink} to="/contact">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.authState.isAuthenticated,
  user: state.authState.user
});

const mapDispatchToProps = {
  logout,
  toggleLoginPopup
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Navbar));
