var CryptoJS = require("crypto-js");

export const encryptandDecrypt = {
  encrypt: (data) => {
    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      "my-secret-key@123"
    ).toString();
    return ciphertext;
  },
  decrypt: (data) => {
    var bytes = CryptoJS.AES.decrypt(data, "my-secret-key@123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  },
};
