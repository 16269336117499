import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCinemas,
  getMovie,
  getReservations,
  getShowtimes,
  setSelectedCinema
} from '../../../../store/actions';

const form = {
  selectedDate: null,
  selectedTime: null,
  count: 2
};

const useBooking = id => {
  const dispath = useDispatch();
  const [bookingForm, setBookingForm] = useState(form);
  const [showtime, setShowtime] = useState([]);
  const [cinemaSelected, setCinemaSelected] = useState(null);

  // take all state previous data
  const filteredShowtimes = useSelector(state =>
    state?.showtimeState?.showtimes?.filter(
      showtime => showtime?.movieId?._id === id
    )
  );
  const cinemasList = useSelector(state => state?.cinemaState?.cinemas);
  const reservations = useSelector(state =>
    state?.reservationState?.reservations?.filter(value => value?.isPrivate)
  );

  const movie = useSelector(state => {
    if (state.movieState?.selectedMovie?._id === id)
      return state.movieState?.selectedMovie;
  });

  //if state not found
  useEffect(() => {
    if (filteredShowtimes.length === 0) dispath(getShowtimes());
    if (!movie) dispath(getMovie(id));
    if (cinemasList.length === 0) dispath(getCinemas());
    if (reservations.length === 0) dispath(getReservations());
  }, []);

  useEffect(() => {
    if (filteredShowtimes.length === 0) return;
    //showtime which having private screen find the showtime with the same date
    const showtimes = filteredShowtimes.filter(
      value =>
        new Date(value?.startDate).toLocaleDateString() ===
          new Date(bookingForm?.selectedDate).toLocaleDateString() &&
        value?.cinemaId?.name === 'Private'
    );

    setShowtime(showtimes);
  }, [bookingForm?.selectedDate]);

  useEffect(() => {
    selectedShow();
  }, [bookingForm?.selectedTime]);

  const reservedCheck = value => {
    return (
      new Date(value?.date).toLocaleDateString() ===
        new Date(bookingForm?.selectedDate).toLocaleDateString() &&
      value?.startAt === bookingForm?.selectedTime &&
      value?.movieId === id
    );
  };

  const selectedShow = () => {
    if (!bookingForm?.selectedTime) return setCinemaSelected(null);
    //only one private show will be selected
    const cinemaShowtime = showtime.find(
      value => String(value?.startAt) === String(bookingForm?.selectedTime)
    );
    const cinema = cinemasList.find(
      value => value?._id === cinemaShowtime?.cinemaId?._id
    );
    //checks the ticket is booked or not

    const isReservedSeat = reservations.some(reservedCheck);
    if (isReservedSeat === false) setCinemaSelected(cinema);
    else setCinemaSelected(null);
  };

  const onChangeDate = date => {
    setBookingForm({
      ...bookingForm,
      selectedDate: date,
      selectedTime: null
    });
  };

  const onChangeTime = value => {
    setBookingForm({
      ...bookingForm,
      selectedTime: value
    });
  };

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const uniqueShowTimes = () => {
    let filtered = filteredShowtimes.map(value =>
      new Date(value?.startDate).toDateString()
    );
    return filtered.filter(onlyUnique);
  };

  const onchangeCount = status => {
    setBookingForm({
      ...bookingForm,
      count: status ? bookingForm?.count + 1 : bookingForm?.count - 1
    });
  };

  return {
    showtime,
    bookingForm,
    onChangeDate,
    onChangeTime,
    movie,
    filteredShowtimes,
    cinemaSelected,
    uniqueShowTimes,
    onchangeCount,
    setBookingForm
  };
};

export default useBooking;
