import { Box, Container, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import { DefaultButton, RedButton } from '../../../../theme/buttons';
import useStyles from './styles';

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Container maxWidth="lg">
        <Box>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={7} sm={4}>
              <Box className={classes.menu}>
                <Link to={'/'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.copyright} variant="body1">
                    Home
                  </Typography>
                </Link>

                <Link to={'/contact'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.copyright} variant="body1">
                    Contact Us
                  </Typography>
                </Link>

                <Link
                  to="/movie/category/nowShowing"
                  style={{ textDecoration: 'none' }}>
                  <Typography className={classes.copyright} variant="body1">
                    Now Showing
                  </Typography>
                </Link>
                <Link to="/snacks&beverages" style={{ textDecoration: 'none' }}>
                  <Typography className={classes.copyright} variant="body1">
                    Snacks
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} style={{ textAlign: 'center' }}>
              <img
                src="/InnovativeMultiplex-Logo.png"
                alt="Innovative Muntiplex"
                height="130px"
                width="124px"
              />
            </Grid>

            <Grid item xs={12} sm={4} className={classes.buttonContainer}>
              <Link to={'/contact'} style={{ textDecoration: 'none' }}>
                <DefaultButton style={{ marginLeft: '0px' }}>
                  Contact Us
                </DefaultButton>
              </Link>
              <Link
                to={'/movie/category/nowShowing'}
                style={{ textDecoration: 'none' }}>
                <RedButton style={{ marginRight: '0px' }}>Book Now</RedButton>
              </Link>
            </Grid>
          </Grid>
        </Box>

        <br />
        <Divider style={{ backgroundColor: 'black' }} />
        <br />

        <Box>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={8} sm={4}>
              <Box
                height="100%"
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Link to={'/faq'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.bottom} variant="body1">
                    FAQ
                  </Typography>
                </Link>
                <Link to={'/help-center'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.bottom} variant="body1">
                    Help Center
                  </Typography>
                </Link>
                <Link to={'/disclaimer'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.bottom} variant="body1">
                    Disclaimer
                  </Typography>
                </Link>
                <Link to={'/privacy-policy'} style={{ textDecoration: 'none' }}>
                  <Typography className={classes.bottom} variant="body1">
                    Privacy Policy
                  </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid item xs={1} sm={4}></Grid>

            <Grid item xs={3} sm={4} style={{ textAlign: 'end' }}>
              <Box height="100%">
                <a
                  href="https://instagram.com/innovativemultiplex?igshid=YmMyMTA2M2Y="
                  target="_blank">
                  <img
                    src="/assets/icons/instagram.svg"
                    alt="Instagram"
                    className={classes.icon}
                  />
                </a>
                <a
                  href="https://www.facebook.com/Innovative-Multiplex-100198336275402"
                  target="_blank">
                  <img
                    src="/assets/icons/facebook.svg"
                    alt="Facebook"
                    className={classes.icon}
                    style={{ margin: '0 15px' }}
                  />
                </a>
                <a href="#" target="_blank">
                  <img
                    src="/assets/icons/linkedin.svg"
                    alt="LinkedIn"
                    className={classes.icon}
                  />
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
