import { TextField, withStyles } from "@material-ui/core";

export const CustomInput = withStyles({
  root: {
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: "10px",
      marginTop: "7px",
      '& fieldset': {
        borderColor: 'white',
        backgroundColor: "white",
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
    '& .MuiInputBase-input': {
      zIndex: 1
    }
  },
})(TextField);