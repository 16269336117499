export default theme => ({
  card: {
    display: 'flex',
    flex: ' 0 0 auto',
    flexDirection: 'column',
    width: 400,
    height: 400,
    boxShadow: `10px 5px 40px 20px ${theme.palette.background.dark}`,
    margin: '60px 30px',
  },
  header: {
    backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '80%',
    padding: '5px 10px',
    width: '100%',
    color: theme.palette.common.white,
  },
  body: {
    height: '20%',
    color: theme.palette.common.white,
    padding: '15px',
    whiteSpace: 'normal',
  },

  // home banner
  homeImage: {
    height: 300,
    backgroundSize: 'contain !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'right center !important',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      height: 300,
      backgroundSize: 'cover !important',
      backgroundRepeat: 'no-repeat !important',
      backgroundPosition: 'center center !important',
    },
  },
  bannerImage: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      height: 150,
      width:"100%",
      marginTop: '10px',
    },
    [theme.breakpoints.down('md')]: {
      // height: 150,
      width:"100%",
      marginTop: '60px',
    },
  },
  bannerImage2: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 100,
      display: 'block',
    },
  },
  infoContainer: {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    // background: 'linear-gradient(to right, rgb(77 88 100), rgb(77 88 100), rgb(77 88 100),  rgb(77 88 100 / 0%))',
    borderRadius: '10px',
  },
  h2: {
    textTransform: 'uppercase',
    fontSize: '30px',
    fontWight: '600',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem !important',
    },
  },

  showtimes: {
    border: '1px solid #dbdde0',
    borderRadius: '10px',
    color: '#dbdde0',
    padding: '5px 10px',
    marginRight: '15px',
  },

  // New movies
  movieImage: {
    height: 390,
    backgroundSize: 'cover !important',
    backgroundRepeat: 'no-repeat !important',
    backgroundPosition: 'center center !important',
    borderRadius: '10px',
    [theme.breakpoints.down('sm')]: {
      height: '180px',
    },
  },
  movieInfoContainer: {
    position: 'absolute',
    top: '0px',
    width: '100%',
    height: '100%',
    padding: '1rem',
    background: '#0000004d',
    borderRadius: '10px',
  },

  movieTitle: {
    textTransform: 'uppercase',
    fontSize: '18px !important',
    fontWight: '600',
    color: 'white',
  },
  language: {
    textTransform: 'capitalize',
    color: '#d1d3d6',
    marginTop: '3px',
  },
});
