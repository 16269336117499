export const removeDuplicates = (oldArray, searchField) => {
  const newArray = [];
  let searchObject = {};

  for (const item in oldArray) {
    searchObject[oldArray[item][searchField]] = oldArray[item];
  }

  for (const item in searchObject) {
    newArray.push(searchObject[item]);
  }
  return newArray;
}