export default theme => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
  },
  h2: {
    fontSize: '2rem',
    color: theme.palette.common.white,
    margin: theme.spacing(3),
    textTransform: 'capitalize',
  },
  button: {},
  carousel: {
    width: '85%',
    height: '100%',
    margin: 'auto',
  },
  arrow: {
    cursor: 'pointer',
    position: 'absolute',
    top: '25px',
    width: '5%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(0,0,0,.5)',
    color: theme.palette.common.white,
    zIndex: 1,

    [theme.breakpoints.up('xs')]: {
      width: '10%',
    },
    [theme.breakpoints.up('sm')]: {
      width: '8.5%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '5%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '3%',
    },

    '&.prevArrow': {
      left: 0,
      justifyContent: 'flex-end',
      background: 'transparent',
      // opacity: ({ currentSlide }) => (currentSlide ? 1 : 0)
    },
    '&.nextArrow': {
      right: 0,
      justifyContent: 'flex-start',
      background: 'transparent',
      // opacity: ({ currentSlide, slideCount }) => currentSlide === slideCount ? 0 : 1
    },
  },
  arrowIcon: {
    background: 'black',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3px',
  },

  slider: {
    '& .slick-slide': {
      padding: theme.spacing(4),

      [theme.breakpoints.up('xs')]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(4),
      },
      [theme.breakpoints.up('xl')]: {
        padding: theme.spacing(5),
      },
    },
  },
});
