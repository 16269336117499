import { Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { Fragment, useEffect, useState } from 'react';
import Backend_API from '../../../../api';
import { useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import color from '@material-ui/core/colors/amber';
import screen from '../../../../assets/images/screen-icon.8dd7f126.svg';
var convert = require('xml-js');

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  rowNumber: {
    color: 'hsla(0,0%,6%,.54)',
    borderRadius: 2,
    padding: theme.spacing(2),
    margin: theme.spacing(0.5),
    fontWeight: 600
    // backgroundColor: '#101357'
  },
  seat: {
    cursor: 'pointer',
    color: 'black',
    borderRadius: 2,
    padding: theme.spacing(1),
    margin: theme.spacing(0.5),
    fontWeight: 600,
    '&:hover': {
      background: 'rgb(120, 205, 4)'
    }

    // background: 'white'
  },
  seatInfoContainer: {
    width: '50%',
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: 'black'
  },

  seatInfo: { marginRight: theme.spacing(2) },

  seatInfoLabel: {
    marginRight: theme.spacing(1),
    display: 'inline-block',
    width: 10,
    height: 10
  },

  [theme.breakpoints.down('sm')]: {
    seat: { padding: theme.spacing(1.2), margin: theme.spacing(0.5) },
    seatInfoContainer: { width: '100%', display: 'block' },
    seatInfo: { marginTop: theme.spacing(2) }
  }
}));

export default function BookingSeats(props) {
  const dispatch = useDispatch();
  const classes = useStyles(props);
  const { cinema } = props;
  return (
    <Fragment>
      <Box width={1} pt={5}>
        {cinema?.seats?.length > 0 &&
          cinema?.seats?.map((seatRows, indexRow) => {
            if (seatRows.length === 0) return;

            return (
              <div key={uuid()}>
                {indexRow === 0 && (
                  <div style={{ margin: '1rem 0' }}>
                    <Typography
                      style={{
                        color: 'black',
                        textAlign: 'center',
                        fontWeight: '600',
                        fontSize: '18px'
                      }}>
                      {cinema?.name}
                    </Typography>
                  </div>
                )}
                <div key={indexRow} className={classes.row}>
                  <Box className={classes.rowNumber}>
                    {String.fromCharCode(65 + indexRow)}
                  </Box>
                  {seatRows.map((seat, index) => {
                    const ind = index + 1;
                    return (
                      <Box
                        style={{
                          minWidth: '38px',
                          height: '38px',
                          borderRadius: '4px',
                          border: '1.5 px solid hsla(0,0%,6%,.13)',
                          background: 'hsla(0,0%,6%,.13)',
                          color: 'white'
                        }}
                        component={Button}
                        key={`seat-${index}`}
                        className={classes.seat}
                        disabled={true}
                        bgcolor={
                          seat === 1
                            ? 'rgb(65, 66, 70)'
                            : seat === 2
                            ? '#00b8f5'
                            : seat === 3
                            ? 'rgb(14, 151, 218)'
                            : 'white'
                        }>
                        {/* {seatAvailable?.includes(ind.toString()) && index + 1} */}
                      </Box>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginTop: '3rem'
        }}>
        <img src={screen} alt="screen" />
      </Box>

      <Box width={1} mt={4}>
        <div className={classes.seatInfoContainer}>
          <div className={classes.seatInfo}>
            <div
              className={classes.seatInfoLabel}
              style={{
                border: '1.5px solid rgb(0, 184, 245)',
                borderRadius: '4px'
              }}></div>
            Seat Available
          </div>
          <div className={classes.seatInfo}>
            <div
              className={classes.seatInfoLabel}
              style={{ background: 'rgba(15, 15, 15, 0.13)' }}></div>
            Reserved Seat
          </div>
          {/* <div className={classes.seatInfo}>
            <div
              className={classes.seatInfoLabel}
              style={{ background: 'rgb(120, 205, 4)' }}></div>
            Selected Seat
          </div> */}
          <div className={classes.seatInfo}>
            <div
              className={classes.seatInfoLabel}
              style={{ background: 'rgb(14, 151, 218)' }}></div>
            Selected Seat
          </div>
        </div>
      </Box>
    </Fragment>
  );
}
