import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button, SvgIcon, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { login, facebookLogin, googleLogin } from '../../../../store/actions';
import { history } from '../../../../utils';
import Backend_API from '../../../../api';
import { setAlert } from '../../../../store/actions';
import GooigleLogo from '../../../../assets/icons/googleLogo.svg.png'
import{auth,provider} from '../googleAuth/config'
import { signInWithPopup } from 'firebase/auth';
import HomePage from '../../HomePage/HomePage';
import Register from '../../Register/Register';

const useStyles = makeStyles(theme => ({
  form: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '125px',
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    color: theme.palette.common.contrastText,
    marginTop: theme.spacing(3)
  },
  socialLogin: {
    margin: theme.spacing(4, 0)
  },
  fields: {
    marginTop: theme.spacing(2)
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2)
    }
  },
  progress: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  loginButton: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  register: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary
  },
  registerUrl: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  googleButton: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#757575',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    fontSize: 14,
    fontWeight: 'bold',
    padding: '8px 16px',
    cursor: 'pointer',
    textTransform:'capitalize',
    width:"100%"
  },
  googleLogo: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
}));


function LoginForm(props) {
  const dispatch = useDispatch()
  const { isAuthenticated, user, redirect } = props;
  const classes = useStyles();
  const [values, setValues] = useState({ email: '', password: '' });
  // const [isEmailRegistered,setIsEmailRegistered] = useState(false)

  useEffect(() => {
    if (isAuthenticated && redirect) {
      if (user && user.role === 'superadmin')
        return history.push('/admin/dashboard');
      return history.push('/');
    }
  }, [isAuthenticated, user, redirect]);

  const handleFieldChange = e =>
    setValues({
      ...values,
      [e.target.name]: e.target.value
    });



    const ForgotPasswordHandler = async(email) =>{
      if(!email)return  dispatch(setAlert('please Enter the email', "error", 5000))   

      const url = Backend_API + "/users/forgot/password"
      const responsedata =  await fetch(url , {
        method:"POST",
        headers: {'Content-Type': 'application/json'},
        body:  JSON.stringify({email})
      })

      const data = await responsedata.json()
      if(responsedata.status === 409){
          dispatch(setAlert( 'Email Not Found', "error", 5000))
        }else{
          dispatch(setAlert('Link has been sent to your registered email',"success", 5000));
      }
      // console.log("response" , responsedata)
      return data;
    }

  
    const checkUserExists = async (email) => {
      const url = `${Backend_API}/users/isuser?email=${email}`;
      const response = await fetch(url);
    
      if (response.ok) {
        const data = await response.json();
        return data.user; // Assuming the response contains a property `exists` indicating user existence
        // console.log(data)
        // if(data.user === true){

        //   setIsEmailRegistered(true)
        // }
      } else {
        throw new Error('Failed to check user existence');
      }
    };
    
    
    const handleClick = () => {
      signInWithPopup(auth, provider).then(async (data) => {
        // console.log("this is user ", data);
        const email = data.user.email;
        const password = data.user.uid;
    
        // Calling backend endpoint to check if the email is registered
        const isEmailRegistered = await checkUserExists(email);
        // console.log("isEmailRegistered isEmailRegistered",isEmailRegistered)
        if (isEmailRegistered) {
          // If email is registered, log the user in
          
          // console.log("logging in")
          props.login(email, password); // Pass password & mail for Google login
        } else {
          dispatch(setAlert('Please add Mobile number to Proceed.', 'success', 5000));
          // If email is not registered, navigate to the register page
          const userData = {
            user: data.user,
            redirectedFromGoogle: true
          };
          localStorage.setItem('userData', JSON.stringify(userData));

          history.push('/register' );
        }
      });
    };
    
    

    
  
  return (
    <form className={classes.form}>
      <Typography className={classes.title} variant="h2">
        Sign in
      </Typography>


      <div className={classes.fields}>
        <TextField
          className={classes.textField}
          label="email"
          name="email"
          onChange={event => handleFieldChange(event)}
          type="text"
          value={values.email}
          variant="outlined"
          required
        />
        <TextField
          className={classes.textField}
          label="Password"
          name="password"
          onChange={event => handleFieldChange(event)}
          type="password"
          value={values.password}
          variant="outlined"
          required
        />
      </div>
       <Typography style={{fontWeight:"600", color:"#0767DB" ,  cursor: "pointer"}} onClick={()=>{ForgotPasswordHandler(values.email )}}  >Forgot Password ?</Typography>   
      <Button
        className={classes.loginButton}
        color="primary"
        onClick={() => props.login(values.email, values.password)}
        size="large"
        variant="contained">
        Login now
      </Button>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'10px'}}>

        
      <Button className={classes.googleButton} onClick={handleClick}>
      <img src={GooigleLogo} alt="Google Logo" className={classes.googleLogo} />
      Sign in with Google
    </Button> 

      </div>
      <Typography className={classes.register} variant="body1">
        Don't have an account?
        <Link className={classes.registerUrl} to="/register">
          register
        </Link>
      </Typography>
      

    </form>
    
  );
}

const mapStateToProps = state => ({
  isAuthenticated: state.authState.isAuthenticated,
  user: state.authState.user
});
export default connect(mapStateToProps, { login, facebookLogin, googleLogin })(
  LoginForm
);
