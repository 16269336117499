import shape from '../../../assets/icons/Shape.png';

export default theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    marginTop: "125px",
    color: 'black'
  },
  bggray: {
    backgroundColor: theme.palette.background.default,
    padding: "50px 0",
  },
  redText: {
    color: theme.palette.common.red,
  },
  divider: {
    height: 1.5,
    width: '30%',
    marginLeft: "3rem",
    background: "red",
  },
  heading: {
    fontSize: "30px",
    fontWeight: 600,
  },
  subtitle: {
    fontSize: "23px",
    fontWeight: 600,
  },
  bannerImage: {
    margin: "auto",
    textAlign: "center",
    backgroundImage: `url(${shape})`,
    backgroundSize: '112%',
    backgroundPosition: 'center',
    backgroundRepeat: "no-repeat",
  },



  whyUsItemContainer: {
    backgroundColor: theme.palette.background.dark,
    borderRadius: '10px'
  },
  iconContainer: {
    backgroundColor: theme.palette.common.red,
    borderRadius: '10px',
    padding: "15px"
  },


  experienceCardContainer: {
    borderRadius: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

  },
  iconContainer2: {
    position: "relative",
    backgroundColor: theme.palette.common.red,
    borderRadius: '0 10px 0 10px',
    padding: "15px",
    margin: "-20px -20px 0 0",
  },


  associationImageContainer: {
    borderRadius: "10px",
    border: "1.5px solid",
    borderColor: theme.palette.background.dark,
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
    overflow: "hidden",

    '&:hover': {
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    },
  },
  associationImage: {
    borderRadius: "10px",

    '&:hover': {
      boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
      transform: "scale(1.3)",
    },
  },
});
