import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    container: { height: '100%', paddingTop: theme.spacing(12) },
    [theme.breakpoints.down('md')]: {
      root: { height: '100%' }
    },
    root:{
        border:"1px  solid #dfe3e7",
        borderRadius:"6px",
        overflow:"hidden",


        
    },
    orderSummary:{
        position: 'relative',
        padding: '30px 25px',
        background: '#fff',

    },
    bookingSummary:{
        position: 'relative',
        fontSize: '14px',
        fontWeight: '300',
        textTransform: 'uppercase',
        letterSpacing: '3px',
        marginBottom: '25px',
        textAlign: 'left',
        color: '#c02c39'
    },
    seat:{
        fontSize: '12px',
        color: '#1f2533',
        fontWeight:400,
        width:"50%"
    },
    orderTotal:{
    borderTop: '1px dotted #ccc',
},
amountPayable:{
    backgroundColor: '#fffcdc',
    padding: '15px 25px',
    minHeight: '50px'
},
info:{
    width: '100%',
color: '#333',
textAlign: 'center',
border: '0',
fontSize: '11px',
paddingBottom: '0',
marginTop:"3rem"
}
,
infoText:{
    fontFamily: 'Roboto',
fontSize: '12px',
lineHeight: '16px',
alignItems: 'center',
letterSpacing: '0.2px',
color: '#404040',
marginLeft: '5px'
},
proceed:{
    width: '100%',
padding: '10px 16px',
justifyContent: 'space-between',
background: '#f84464',
color: '#fff',
display: 'flex',
justifyContent: 'center',
alignItems: 'center',
width: '100%',
height: '100%',
boxShadow: '0 1px 8px rgba(0,0,0,.16)',
borderRadius: '8px',
cursor: 'pointer',
minHeight: '40px',
maxWidth: '400px',
marginTop:"2rem"
}

  }));
const Summary = ({handlePayment,data}) => {
    const classes = useStyles()
    console.log(data);
  return (
    <div>
    {/* wrapper */}
    <div className={classes.root}>
        <div className={classes.orderSummary}>
            <h1 className={classes.bookingSummary}>Booking Summary</h1>
            <div style={{gap:"8px",display:"flex",flexDirection:"column"}}>

            <div style={{display:"flex",justifyContent:"space-between",alignItems:"start"}}>
                <p className={classes.seat} >Movie Name</p>
                <p className={classes.seat} style={{textAlign:"right"}}>{data?.movie?.title}</p>

            </div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <p className={classes.seat}>Total Tickets</p>
                <p className={classes.seat} style={{textAlign:"right"}}>{data?.selectedSeats?.length}</p>

            </div>
            <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <p className={classes.seat}>Seats Selected</p>
                <p className={classes.seat} style={{textAlign:"right"}}>{data?.cinema?.split("-")[1]} - {data?.convertedSeats?.map((v) => <span style={{marginRight:".2rem"}}>{v}</span>)}</p>

            </div>
            </div>
        </div>
        <div className={classes.orderTotal}></div>
            <div className={classes.orderSummary}>
            <div style={{display:"flex",justifyContent:"space-between"}}>
                <p className={classes.seat}>Sub Total</p>
                <p className={classes.seat} style={{textAlign:"right"}}>Rs. {data?.selectedSeats?.length *data?.ticketPrice}</p>

            </div>
            </div>
        <div className={classes.amountPayable}>
        <div style={{display:"flex",justifyContent:"space-between"}}>
                <p className={classes.seat} style={{fontSize:"14px"}}>Amount Payable</p>
                <p className={classes.seat} style={{fontSize:"14px",textAlign:"right"}}>Rs. {data?.selectedSeats?.length *data?.ticketPrice}</p>

            </div>
        </div>
    </div>
    <div className={classes.info} >Show the m-ticket QR Code on your mobile to enter the cinema.</div>
    <div>

    <div style={{width: "13px", height: "13px"}}>
                        		<svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" >
									<use ></use>
								</svg>
                        	</div>
    <div className={classes.infoText}>
                        		By proceeding, I express my consent to complete this transaction.
                        	</div>
    </div>
    <div className={classes.proceed} onClick={handlePayment}>
        Proceed
    </div>
    </div>
  )
}

export default Summary