import { Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles(theme => ({
  
  movieInfo: {
    background: '#afa38d',
    // position: 'relative',
    height: '42%',
    maxHeight:'44%',
    width:'100vw',
    overflow:'hidden',
    // marginBottom:'-150px',
    // marginTop:'-60px'
  },
  backgroundImage: {
    opacity: 1,
    height: '36vh',
    maxHeight:'36vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    zIndex: 1,
    margin:'auto',
    // marginTop:'1px',
    padding:'10px',
    objectFit: 'contain',
  
  },
 
  info: {
    padding: theme.spacing(5),
    width: '100%',
    marginTop:'-20px'
  },
  infoBox: {
    color: "#000",
    marginBottom: theme.spacing(2),
   
  },
  gradientTexts: {
    backgroundImage: 'linear-gradient(87.4deg, rgb(160, 91, 43) 1.4%, rgb(101, 52, 41) 49.7%, rgb(83, 54, 54) 100.5%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    wordWrap: 'break-word',
    fontSize:'26px',
    lineHeight:'32px',
    
  },
  [theme.breakpoints.down('md')]: {
    movieInfo: { minHeight: '30vh' },
    // title: { top: '80%' },
    info: { display: 'none' }
  },
  
  
}));

const MovieInfoMobile = (props) => {
  const classes = useStyles(props);

  const{movie,selectedTime} = props
  return (
    <>
<h2 className={classes.gradientTexts} style={{color:'black',textTransform:'capitalize',marginBottom:'10px',fontWeight:'900',overflowWrap: 'break-word'}}>{movie.title}</h2>
      {
        selectedTime ? (
          ''
        ) : (
          <div className={classes.movieInfo}>
          <img src={movie.image} alt='movie-poster' className={classes.backgroundImage}/>
        </div>
          
        )
      }
      {/* <div className={classes.movieInfo}>
        <img src={movie.image} alt='movie-poster' className={classes.backgroundImage}/>
      </div> */}
    </>
  )
}

export default MovieInfoMobile