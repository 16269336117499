import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';
import { useMediaQuery } from 'react-responsive'
import MovieInfoMobile from './MovieInfo.mobile';



const useStyles = makeStyles(theme => ({
  movieInfo: {
    background: '#afa38d',
    position: 'relative',
    height: '95%',
    maxHeight:'108vh'
  },
  background: {
    opacity: 1,
    height: '70%',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    width: '90%',
    zIndex: 1,
    margin:'10px',
    padding:'10px'
  },
  title: {
    position: 'absolute',
    top: '60%',
    right: 0,
    width: '100%',
    textAlign: 'center',
    color: theme.palette.common.white,
    fontSize: '24px',
    textTransform: 'capitalize',
    zIndex: 2
  },
  info: {
    padding: theme.spacing(5),
    width: '100%',
    marginTop:'-20px'
  },
  infoBox: {
    color: "#000",
    marginBottom: theme.spacing(2),
   
  },
  gradientText: {
    backgroundImage: 'linear-gradient(87.4deg, rgb(160, 91, 43) 1.4%, rgb(101, 52, 41) 49.7%, rgb(83, 54, 54) 100.5%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  [theme.breakpoints.down('md')]: {
    movieInfo: { minHeight: '30vh' },
    background: { height: '100%' },
    title: { top: '80%' },
    info: { display: 'none' }
  },
  [theme.breakpoints.up('md')]: {
    movieInfo: { minHeight: '30vh',maxHeight: '70vh',minWidth:'20vw' },
    background: { height: '80%' },
  },
  [theme.breakpoints.down('lg')]: {
    // Breakpoint for tablet devices
    movieInfo: { maxHeight: '108vh' ,minWidth:'20vw'},
    background: { maxHeight: '70vh' },
  },
  [theme.breakpoints.down('sm')]: {
    movieInfo: { maxHeight: '108vh' ,width:'100vw'},
    background: { maxHeight: '70vh' },
  },
  
}));

export default function MovieInfo(props) {
  const classes = useStyles(props);
  const { movie,selectedTime } = props;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' })

  if (!movie) return <h1>Movie Loading...</h1>;

  return (
    <>
    {isTabletOrMobile ? <MovieInfoMobile movie={movie} selectedTime={selectedTime}/> :
    <Grid item xs={12} md={12} lg={3} >
      <h2 className={classes.gradientText} style={{color:'black',textTransform:'capitalize',marginBottom:'6px',fontWeight:'900'}}>{movie.title}</h2>
      <div className={classes.movieInfo}>
        <div
          style={{
            display:'flex',
            alignItems:'center',
            width:'104%'
          }}
        > <img src={movie.image} alt='movie-poster' className={classes.background}/></div>
        <div className={classes.info}>
          {movie.director && (
            <div className={classes.infoBox}>
              <Typography variant="subtitle1" color="inherit" style={{ fontWeight: '700',fontSize:'16px'}}>
                Director
              </Typography>
              <Typography variant="caption" color="inherit" style={{ fontWeight: '600',fontSize:'12px'}}>
                {movie.director}
              </Typography>
            </div>
          )}
          {movie.cast && (
            <div className={classes.infoBox}>
              <Typography variant="subtitle1" color="inherit" style={{ fontWeight: '700',fontSize:'16px'}}>
                Cast
              </Typography>
              <Typography variant="caption" color="inherit" style={{ fontWeight: '600',fontSize:'12px'}}>
                {movie.cast}
              </Typography>
            </div>
          )}
          {movie.genre && (
            <div className={classes.infoBox}>
              <Typography variant="subtitle1" color="inherit" style={{ fontWeight: '700',fontSize:'16px'}}>
                Genre
              </Typography>
              <Typography variant="caption" color="inherit" style={{ fontWeight: '600',fontSize:'12px'}}>
                {movie.genre}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </Grid>
     }
    </>
  );
}
