import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import BookingImg from '../../../assets/banners/Experience Image.png';
import IMSlant from '../../../assets/icons/IM - Icon.png';
import theme from '../../../theme';
import { RedButton } from '../../../theme/buttons';

const useStyles = makeStyles({
  heading: {
    fontSize: '30px',
    color: 'black',
    fontWeight: 600,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem'
    }
  },
  bookingImage: {
    marginRight: '0px',
    height: '300px',
    width: '300px',
    position: 'absolute',
    top: '-50px',
    right: '300px',
    display: 'block',
    [theme.breakpoints.up('xs')]: {
      display: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      right: '150px'
    },
    [theme.breakpoints.up('md')]: {
      right: '300px'
    }
  },
  icon1: {
    marginRight: '0px',
    height: '120px',
    width: '120px',
    position: 'absolute',
    top: '50px',
    right: '100px',
    [theme.breakpoints.up('xs')]: {
      right: '0px'
    },
    [theme.breakpoints.up('sm')]: {
      right: '0px'
    }
  },
  icon2: {
    marginRight: '0px',
    height: '50px',
    width: '50px',
    position: 'absolute',
    top: '100px',
    right: '200px',
    [theme.breakpoints.up('xs')]: {
      right: '100px'
    }
  }
});

const ExclusiveCard = () => {
  const classes = useStyles();

  return (
    <Box style={{ margin: '50px 0px' }}>
      <Box style={{ position: 'relative' }}>
        <Box
          className={{
            position: 'absolute',
            top: '0px',
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            background:
              'linear-gradient(to right, rgb(77 88 100), rgb(77 88 100), rgb(77 88 100),  rgb(77 88 100 / 0%))',
            borderRadius: '10px'
          }}
          style={{
            backgroundColor: theme.palette.background.dark
          }}>
          <img className={classes.bookingImage} src={BookingImg} alt=""></img>
          <img className={classes.icon1} src={IMSlant} alt=""></img>
          <img className={classes.icon2} src={IMSlant} alt=""></img>
          <br /> <br /> <br />
          <Container maxWidth="lg">
            <Typography className={classes.heading} variant="h4" component="h4">
              The Exclusive Cinema Experience
            </Typography>

            <br />
            <Link to={`/movie/category/nowShowing`}>
              <RedButton style={{ marginLeft: '0px' }}>Book Now</RedButton>
            </Link>
          </Container>
          <br /> <br /> <br />
        </Box>
      </Box>
    </Box>
  );
};
export default ExclusiveCard;
